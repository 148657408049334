// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-items .ant-table-expanded-row .ant-table-wrapper {
  max-width: calc(1000 / 16 * 1rem);
  width: auto;
}
.billing-items .ant-table-expanded-row .ant-table .ant-table-container::after {
  box-shadow: none;
}
.billing-items__export {
  display: flex;
  justify-content: flex-end;
}
.billing-items__export .ant-form-item {
  margin-bottom: 0;
  padding-bottom: 0;
}
.billing-items__export .ant-form-item:first-child {
  margin-right: calc(10 / 16 * 1rem);
}

.table-advanced-filter--billing_items {
  width: calc(460 / 16 * 1rem) !important;
}
.table-advanced-filter--billing_items .ant-picker-range {
  width: calc(400 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/sites/BillingReports/billing-items/billing-items.scss"],"names":[],"mappings":"AAII;EACE,iCAAA;EACA,WAAA;AAHN;AAMI;EACE,gBAAA;AAJN;AAQE;EACE,aAAA;EACA,yBAAA;AANJ;AAOI;EACE,gBAAA;EACA,iBAAA;AALN;AAMM;EACE,kCAAA;AAJR;;AAUA;EACE,uCAAA;AAPF;AAQE;EACE,4BAAA;AANJ","sourcesContent":["@import '~mixins';\n\n.billing-items {\n  .ant-table-expanded-row {\n    .ant-table-wrapper {\n      max-width: toRem(1000);\n      width: auto;\n    }\n\n    .ant-table .ant-table-container::after {\n      box-shadow: none;\n    }\n  }\n\n  &__export {\n    display: flex;\n    justify-content: flex-end;\n    .ant-form-item {\n      margin-bottom: 0;\n      padding-bottom: 0;\n      &:first-child {\n        margin-right: toRem(10);\n      }\n    }\n  }\n}\n\n.table-advanced-filter--billing_items {\n  width: toRem(460) !important;\n  .ant-picker-range {\n    width: toRem(400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
