import React from 'react'

import {Tooltip} from 'antd'

import {truncateString} from '@/utils/truncate'

const TABLE_MAX_CHARS = 80

const TooltipWithTruncate = ({name}) => {
  return (
    <Tooltip
      title={name.length > TABLE_MAX_CHARS ? name : null}
      placement="top"
    >
      <span>{truncateString(name, 80)}</span>
    </Tooltip>
  )
}

export default TooltipWithTruncate
