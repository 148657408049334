import {useCallback, useState} from 'react'

import {getChannels as getChannelsData} from '@/api/spacesRequests'
import {useRequestStatusHooks} from '@/hooks/use-request-status-hooks'
import {sortArrayCaseInsensitive} from '@/utils/sort-arr-by-value'
import {TItsmChannel} from '@/modules/ITSM/typedef'
import {getBEndToken} from '@/api/token-request'
// import {getBackendTokenHeaders} from '@/services/api'  Nikola
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'

export const useGetChannels = () => {
  const [channels, setChannels] = useState<TItsmChannel[]>([])
  const {
    status,
    handleRequest,
    handleSuccess,
    handleFail,
    handleReset,
  } = useRequestStatusHooks()

  const getChannels = useCallback(async () => {
    try {
      handleRequest()
      const {
        body: {spaces},
      } = await getChannelsData(getToken())  //getBackendTokenHeaders
      handleSuccess()
      const result = sortArrayCaseInsensitive(spaces)
      setChannels(result)
      return result
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }, [handleFail, handleRequest, handleReset, handleSuccess])

  const resetChannels = useCallback(() => {
    setChannels([])
  }, [])

  return {
    getChannels,
    channels,
    status,
    resetChannels,
  }
}

export const useGetChannelsLocal = () => {
  const [channels, setChannels] = useState<TItsmChannel[]>([])
  const {
    status,
    handleRequest,
    handleSuccess,
    handleFail,
    handleReset,
  } = useRequestStatusHooks()

  const getChannels = useCallback(
    async (orgId?: string) => {
      try {
        handleRequest()
        const refreshedToken = await getBEndToken({id: orgId})

        const {
          body: {spaces},
        } = await getChannelsData({
          Authorization: `Bearer ${refreshedToken.body.access_token}`,
        })
        handleSuccess()
        const result = sortArrayCaseInsensitive(spaces)
        setChannels(result)
        return result
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequest, handleReset, handleSuccess]
  )

  const resetChannels = useCallback(() => {
    setChannels([])
  }, [])

  return {
    getChannels,
    channels,
    status,
    resetChannels,
  }
}
