// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-search-table-header {
  width: calc(187 / 16 * 1rem);
  height: calc(40 / 16 * 1rem) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/list-table-header/quick-search-table-header.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,uCAAA;AADF","sourcesContent":["@import '~mixins';\n\n.quick-search-table-header {\n  width: toRem(187);\n  height: toRem(40) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
