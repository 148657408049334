// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  display: flex;
  align-items: center;
}
.progress-bar .anticon {
  margin-left: calc(5 / 16 * 1rem);
}
.progress-bar .ant-tooltip-inner {
  font-size: calc(12 / 16 * 1rem);
}
.progress-bar .ant-tooltip-inner span {
  width: calc(100 / 16 * 1rem);
  display: inline-block;
}
.progress-bar .ant-progress {
  width: calc(140 / 16 * 1rem);
  margin-right: calc(10 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/incident-k-request/incident-request-progress-bar/incident-request-progress-bar.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AAEE;EACE,gCAAA;AAAJ;AAEE;EACE,+BAAA;AAAJ;AACI;EACE,4BAAA;EACA,qBAAA;AACN;AAEE;EACE,4BAAA;EACA,kCAAA;AAAJ","sourcesContent":["@import '~mixins';\n\n.progress-bar {\n  display: flex;\n  align-items: center;\n  .anticon {\n    margin-left: toRem(5);\n  }\n  .ant-tooltip-inner {\n    font-size: toRem(12);\n    span {\n      width: toRem(100);\n      display: inline-block;\n    }\n  }\n  .ant-progress {\n    width: toRem(140);\n    margin-right: toRem(10);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
