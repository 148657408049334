import {call, put, select} from 'redux-saga/effects'
import queryString from 'query-string'

import {fetchProfile, postMyProfile} from '@/api/my-profile-requests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {
  saveGeneralProfileFailed,
  saveGeneralProfileRequested,
  saveGeneralProfileSuccess,
} from '@/redux/profile/profileActions'
import {
  setAccounts,
  setItsmChannel,
  setOrganization,
} from '@/redux/entries/entries-actions'
import {
  selectChannelID,
  selectChannelName,
  selectOrgId,
  selectOrgName,
} from '@/redux/entries/entries-selector'
import {
  getIdentityMe,
  refreshResetStatus,
  setBETokenSuccess,
} from '@/redux/user/userActions'
import {getModalErrorData} from '@/utils/get-modal-error-data'
import {translate} from '@/services/i18n'

import {
  fetchBillingSubscriptions,
  fetchCustomerRequested,
} from '@/redux/billing/billing-action-creators'

import {getBEndToken} from '@/api/token-request'
import {getBackendTokenHeaders} from '@/services/api'
import {refreshTokenSaga} from '@/saga/refresh-token-saga'
import {getMyAccountsTS, getOrgsTS} from '@/api/account-requests'
import {getVersionReq, setRedirection} from '@/redux/ui/uiActions'
import {generateRedirectPath} from '@/utils/generate-redirect-path'
import globalRoutes from '@/global-routes'

import {fetchMySpacesTS} from '@/modules/ITSM/api/itsm-org-requests'
// import {getAccountsSuccess} from '@/redux/accounts/accountsActions'
import {
  getCreditAccessReq,
  getMyAccessReq,
} from '@/redux/settings/settingsActions'
import {selectCreditAccess} from '@/redux/settings/settingsSelector'
import {fetchCmdbAccountIdentityMe} from '@/modules/CMDB/store/user/cmdb-user-actions'
import {fetchSaasIdentityMe} from '@/modules/modules-shared/shared-cmdb/redux/identity/identityActions'
import {getToken} from '../services/api'

export function* profileSaga({payload: {orgQuery, channelQuery}}) {
  
  const channelIDPersisted = yield select(selectChannelID)
  const orgIdPersisted = yield select(selectOrgId)
  const channelNamePersisted = yield select(selectChannelName)
  const orgNamePersisted = yield select(selectOrgName)
  const creditAccess = yield select(selectCreditAccess)
  
  try {
    yield* refreshTokenSaga({payload: {id: orgIdPersisted}})
    yield put(saveGeneralProfileRequested()) 
    const {body: accounts} = yield call(
      getMyAccountsTS,
      getBackendTokenHeaders()
    )
        
    if (accounts.length === 0) {
      return yield put(
        setRedirection(
          `${globalRoutes.general.accountDefault.path()}${decodeURI(
            window.location.search
          )}`
        )
      )
    }

    yield put(setAccounts(accounts))

    const {
      body: {profile},
    } = yield call(fetchProfile, getBackendTokenHeaders())
    
    if (!profile?.accountId || !profile.channelId || !profile?.orgId) {
      if (accounts.length > 1) {
        return yield put(
          setRedirection(globalRoutes.general.profileDefault.path())
        )
      }
      if (accounts.length === 1) {
        const {body: accountOrgs} = yield call(getOrgsTS, {
          accountId: accounts[0].id,
          headers: getBackendTokenHeaders(),
        })

        if (!accountOrgs || accountOrgs.length === 0) {
          return yield put(
            setToastMessage({
              message: getModalErrorData(translate('smth_went_wrong')),
            })
          )
        }
        if (accountOrgs.length > 1) {
          return yield put(
            setRedirection(globalRoutes.general.profileDefault.path())
          )
        }
        if (accountOrgs.length === 1) {
          const {
            body: {access_token, expires_in},
          } = yield call(getBEndToken, {
            id: accountOrgs[0].id,
          })

          yield put(
            setBETokenSuccess({
              token: access_token,
              tokenExp: expires_in,
            })
          )
          const {
            body: {spaces},
          } = yield call(fetchMySpacesTS, {Authorization: `Bearer ${access_token}`})

          if (!spaces) {
            return yield put(
              setToastMessage({
                message: getModalErrorData(translate('smth_went_wrong')),
              })
            )
          }
          if (spaces.length === 0) {
            return yield put(
              setRedirection(
                `${globalRoutes.general.accountDefault.path()}${decodeURI(
                  window.location.search
                )}`
              )
            )
          }
          if (spaces.length > 1) {
            return yield put(
              setRedirection(globalRoutes.general.profileDefault.path())
            )
          }
          if (spaces.length === 1) {
            const profileData = {
              accountId: accounts[0].id,
              orgId: accountOrgs[0].id,
              channelId: spaces[0].space,
            }

            yield call(postMyProfile, profileData)
            return yield put(saveGeneralProfileSuccess(profileData))
          }
        }
      }
    }

    const isAccIdValid = accounts.some(acc => acc.id === profile?.accountId)
    if (!isAccIdValid) {
      return yield put(
        setRedirection(globalRoutes.general.profileDefault.path())
      )
    }

    const {body: accountOrgs} = yield call(getOrgsTS, {
      accountId: profile?.accountId,
      headers: getBackendTokenHeaders(),
    })

    if (accountOrgs?.length === 0) {
      return put(saveGeneralProfileFailed())
    }

    const isOrgIdValid = accountOrgs.some(org => org.id === profile?.orgId)

    if (!isOrgIdValid) {
      return yield put(
        setRedirection(globalRoutes.general.profileDefault.path())
      )
    }
    const {
      body: {access_token, expires_in},
    } = yield call(getBEndToken, {
      id: profile?.orgId,
    })
    yield put(
      setBETokenSuccess({
        token: access_token,
        tokenExp: expires_in,
      })
    )
    const {
      body: {spaces},
    } = yield call(fetchMySpacesTS, {Authorization: `Bearer ${access_token}`})
    if (!spaces || spaces.length === 0) {
      return yield put(
        setToastMessage({
          message: getModalErrorData(translate('smth_went_wrong')),
        })
      )
    }
    const isChannelValid = spaces.some(({space}) => space === profile.channelId)
    if (!isChannelValid) {
      return yield put(
        setRedirection(globalRoutes.general.profileDefault.path())
      )
    }
    yield put(saveGeneralProfileSuccess(profile))
    yield put(fetchBillingSubscriptions())
    yield put(fetchCustomerRequested())

    const isPersisted =
      orgNamePersisted &&
      channelNamePersisted &&
      channelIDPersisted &&
      orgIdPersisted

    yield put(
      setOrganization(
        isPersisted
          ? {orgName: orgNamePersisted, orgID: orgIdPersisted}
          : {
              orgName: accountOrgs.find(org => org.id === profile.orgId).name,
              orgID: orgQuery || profile.orgId,
            }
      )
    )
    yield put(
      setItsmChannel(
        isPersisted
          ? {channelName: channelNamePersisted, channelID: channelIDPersisted}
          : {
              channelName: spaces.find(({space}) => space === profile.channelId)
                .name,
              channelID: channelQuery || profile.channelId,
            }
      )
    )
    yield put(getIdentityMe())
    // yield put(fetchCmdbAccountIdentityMe())
    // yield put(fetchSaasIdentityMe())

    yield put(getMyAccessReq())
    yield put(getVersionReq())

    if (creditAccess === null) {
      yield put(getCreditAccessReq())
    }

    const query = window.location.search
    if (queryString.parse(query).redirectTo) {
      const {pathname, search} = generateRedirectPath(query)

      yield put(setRedirection(`${pathname}${search ? `?${search}` : ''}`))
    }
  } catch (err) {
    yield put(saveGeneralProfileFailed(err))

    yield put(
      setToastMessage({
        message: getModalErrorData(err, translate('smth_went_wrong')),
      })
    )
  } finally {
    yield put(refreshResetStatus())
  }
}
