// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-values__location-container {
  width: calc(100 / 16 * 1rem);
}
.table-values__incidents-progressbar {
  display: flex;
  align-items: center;
}
.table-values__incidents-progressbar .anticon {
  margin-left: calc(5 / 16 * 1rem);
}
.table-values__incidents-progressbar .ant-tooltip-inner {
  font-size: calc(12 / 16 * 1rem);
}
.table-values__incidents-progressbar .ant-tooltip-inner span {
  width: calc(100 / 16 * 1rem);
  display: inline-block;
}
.table-values__incidents-progressbar .ant-progress {
  width: calc(140 / 16 * 1rem);
  margin-right: calc(10 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/utils/table/get-table-values/get-table-values.scss"],"names":[],"mappings":"AAIE;EACE,4BAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;AAJJ;AAKI;EACE,gCAAA;AAHN;AAKI;EACE,+BAAA;AAHN;AAIM;EACE,4BAAA;EACA,qBAAA;AAFR;AAKI;EACE,4BAAA;EACA,kCAAA;AAHN","sourcesContent":["@import '~mixins';\n\n.table-values {\n\n  &__location-container {\n    width: toRem(100);\n  }\n\n  &__incidents-progressbar {\n    display: flex;\n    align-items: center;\n    .anticon {\n      margin-left: toRem(5);\n    }\n    .ant-tooltip-inner {\n      font-size: toRem(12);\n      span {\n        width: toRem(100);\n        display: inline-block;\n      }\n    }\n    .ant-progress {\n      width: toRem(140);\n      margin-right: toRem(10);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
