import React from 'react'

export const KompitechWithName = ({width, height}) => (
  <svg
    version="1.0"
    id="Vrstva_1"
    width={width}
    height={height}
    x={0}
    y={0}
    viewBox="0 0 730 250"
  >
    <style type="text/css">
      {'.st0{fill:#FFE000}'}
      {'.st1{fill:#10121F}'}
      {'.st2{display:none;fill:#ED1C24}'}
      {'.st3{font-family: Mont-Bold }'}
      {'.st4{font-size:153px}'}
      {'.st5{letter-spacing:-4}'}
    </style>
    <g id="Logo_3_">
      <path
        className="st0"
        d="M206.4,0H84.8C37.9,0,0,37.9,0,84.8v121.6C0,217.2,8.8,226,19.7,226h121.6c46.8,0,84.8-37.9,84.8-84.8V19.7
        C226,8.8,217.2,0,206.4,0z M184.6,89.2h-47.7v47.7h47.7c0,26.4-21.4,47.7-47.7,47.7v-47.7H89.2v47.7H55.4c-7.7,0-14-6.3-14-14
        v-33.7V89.2c0-26.3,21.4-47.7,47.7-47.7v47.7h47.7V41.5H171c7.5,0,13.6,6.1,13.6,13.6V89.2z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M319.8,65.1l-13.4-17.8h-5v17.8h-7V24.6h7v16.6h5l12.2-16.6h8.6l-14.6,19.6l15.9,21H319.8z"
      />
      <path
        className="st1"
        d="M352.9,63.8c-2.7,1.4-5.7,2.1-9,2.1c-3.3,0-6.3-0.7-9-2.1c-2.7-1.4-4.8-3.4-6.3-5.9c-1.5-2.5-2.3-5.3-2.3-8.4
        c0-3.1,0.8-5.9,2.3-8.4c1.5-2.5,3.6-4.5,6.3-5.9c2.7-1.4,5.7-2.1,9-2.1c3.3,0,6.3,0.7,9,2.1c2.7,1.4,4.8,3.4,6.3,5.9
        c1.5,2.5,2.3,5.3,2.3,8.4s-0.8,5.9-2.3,8.4C357.7,60.4,355.6,62.4,352.9,63.8z M336.2,56.9c2,1.9,4.6,2.9,7.7,2.9
        c3.1,0,5.7-1,7.7-2.9c2-1.9,3-4.4,3-7.4c0-3-1-5.5-3-7.4c-2-1.9-4.6-2.9-7.7-2.9c-3.1,0-5.7,1-7.7,2.9c-2,1.9-3,4.4-3,7.4
        C333.2,52.5,334.2,55,336.2,56.9z"
      />
      <path
        className="st1"
        d="M403.7,33.1c3.5,0,6.3,1.1,8.4,3.4c2.2,2.3,3.2,5.2,3.2,8.7v19.9h-6.8V46.8c0-2.5-0.6-4.5-1.7-5.9
        c-1.1-1.4-2.7-2.1-4.7-2.1c-2.2,0-4,0.9-5.5,2.6c-1.5,1.7-2.2,3.9-2.2,6.5v17.1h-6.6V46.8c0-2.5-0.6-4.5-1.7-5.9
        c-1.1-1.4-2.7-2.1-4.8-2.1c-2.2,0-4.1,0.9-5.5,2.6c-1.5,1.7-2.2,3.9-2.2,6.5v17.1h-6.8V33.9h4.8l1.2,4c1.3-1.5,2.8-2.7,4.6-3.5
        c1.8-0.8,3.7-1.3,5.7-1.3c2.2,0,4.2,0.5,5.9,1.5c1.7,1,3.1,2.3,4,4c1.2-1.7,2.8-3,4.6-4C399.5,33.6,401.5,33.1,403.7,33.1z"
      />
      <path
        className="st1"
        d="M440.3,33.1c3.2,0,6.2,0.7,8.8,2.1c2.6,1.4,4.6,3.4,6.1,5.9c1.5,2.5,2.2,5.3,2.2,8.4c0,4.7-1.6,8.6-4.8,11.7
        c-3.2,3.1-7.3,4.7-12.2,4.7c-4.2,0-7.9-1.3-11-3.8v14.2h-6.8V33.9h4.6l1.3,3.8c1.5-1.5,3.3-2.6,5.3-3.4
        C435.8,33.5,438,33.1,440.3,33.1z M432.1,56.8c2.1,2,4.6,2.9,7.7,2.9s5.7-1,7.7-2.9c2-2,3-4.4,3-7.3c0-2.9-1-5.4-3-7.3
        c-2-2-4.6-2.9-7.7-2.9s-5.7,1-7.7,2.9c-2.1,2-3.1,4.4-3.1,7.3S430,54.9,432.1,56.8z"
      />
      <path
        className="st1"
        d="M462.6,29v-6.6h7V29H462.6z M462.7,65.1V33.9h6.8v31.3H462.7z"
      />
      <path
        className="st1"
        d="M505.2,24.6l0,6.2h-12.5v34.4h-7V30.8h-12.5v-6.2H505.2z"
      />
      <path
        className="st1"
        d="M536.3,49.6c0,0.6,0,1.3-0.1,2.1h-26.7c0.4,2.5,1.5,4.5,3.3,6c1.8,1.5,4.1,2.2,6.8,2.2c1.9,0,3.7-0.4,5.2-1.2
        c1.5-0.8,2.7-1.9,3.6-3.4h7.1c-1.3,3.3-3.4,5.9-6.2,7.8c-2.8,1.9-6.1,2.8-9.8,2.8c-4.8,0-8.8-1.6-12.1-4.7c-3.2-3.1-4.9-7-4.9-11.7
        c0-3.1,0.7-5.9,2.2-8.4c1.5-2.5,3.5-4.5,6.1-5.9c2.6-1.4,5.5-2.1,8.6-2.1c3.3,0,6.2,0.7,8.8,2.2c2.6,1.5,4.6,3.5,5.9,6
        C535.6,43.8,536.3,46.5,536.3,49.6z M519.5,38.9c-2.5,0-4.7,0.7-6.4,2c-1.8,1.4-2.9,3.2-3.5,5.5h19.9c-0.6-2.4-1.8-4.2-3.6-5.6
        C524.1,39.6,522,38.9,519.5,38.9z"
      />
      <path
        className="st1"
        d="M556.4,65.9c-3.2,0-6.1-0.7-8.8-2.2c-2.6-1.4-4.7-3.4-6.2-5.9c-1.5-2.5-2.2-5.3-2.2-8.4s0.7-5.9,2.2-8.4
        c1.5-2.5,3.6-4.5,6.2-5.9c2.6-1.4,5.6-2.1,8.8-2.1c4.1,0,7.6,1.1,10.5,3.3c3,2.2,4.9,5.1,5.8,8.7H566c-0.9-1.8-2.2-3.2-3.9-4.2
        c-1.7-1-3.6-1.5-5.8-1.5c-3,0-5.4,1-7.4,2.9c-2,2-3,4.4-3,7.3c0,2.9,1,5.3,3,7.3c2,2,4.4,3,7.4,3c2.2,0,4.1-0.5,5.8-1.6
        c1.7-1.1,3-2.5,3.9-4.4h6.8c-0.9,3.7-2.9,6.6-5.9,8.8C564,64.8,560.4,65.9,556.4,65.9z"
      />
      <path
        className="st1"
        d="M595.1,33.1c3.8,0,6.9,1.2,9.1,3.6c2.2,2.4,3.4,5.6,3.4,9.6v18.8h-6.8V47.6c0-2.8-0.6-5-1.9-6.5
        c-1.3-1.5-3.2-2.2-5.7-2.2c-2.6,0-4.7,0.9-6.3,2.7s-2.5,4-2.5,6.8v16.8h-6.8V22.6h6.8v14.8c1.3-1.4,2.9-2.4,4.7-3.2
        S592.9,33.1,595.1,33.1z"
      />
    </g>
    <text
      transform="matrix(1 0 0 0.95 282.685 203.8383)"
      className="st2 st3 st4 st5"
    >
      BLiTS
    </text>
    <g>
      <path
        className="st1"
        d="M359.3,150.2c4.7,2.3,8.4,5.6,11,9.8c2.6,4.2,3.9,9,3.9,14.4c0,8.1-3.3,15.1-9.9,20.8
        c-6.6,5.7-14.6,8.6-23.9,8.6h-45.5V102.1h44.3c9.2,0,16.9,2.5,23,7.5s9.2,11.2,9.2,18.7c0,4.4-1.1,8.5-3.2,12.3
        C366.1,144.5,363.1,147.7,359.3,150.2z M345.7,139.5c2.2-2,3.2-4.6,3.2-7.8c0-3.2-1.1-5.8-3.3-7.8c-2.2-2-5.1-3-8.7-3h-19.3v21.6
        H337C340.6,142.5,343.5,141.5,345.7,139.5z M338.3,183.6c3.8,0,6.8-1.1,9.2-3.2c2.3-2.1,3.5-4.9,3.5-8.2c0-3.3-1.2-6-3.5-8.2
        c-2.3-2.1-5.4-3.2-9.2-3.2h-20.5v22.8H338.3z"
      />
      <path
        className="st1"
        d="M411.7,183.4h41.1v20.4h-64.2V102.1h23.2V183.4z"
      />
      <path
        className="st1"
        d="M462.3,114.9V95.2h22.3L462.3,114.9z M462.3,203.8v-78.5h22.3v78.5H462.3z"
      />
      <path
        className="st1"
        d="M575.5,102.1v20.4h-29.8v81.3h-23.2v-81.3h-29.7v-20.4H575.5z"
      />
      <path
        className="st1"
        d="M621,205.8c-12.5,0-22.5-3.1-30.1-9.4s-11.4-14.8-11.4-25.6h23.3c0,4.7,1.7,8.3,5.2,10.8
        c3.5,2.5,7.8,3.8,12.8,3.8c4.6,0,8.7-1.1,12.1-3.3c3.4-2.2,5.2-5.1,5.2-8.7c0-1.3-0.2-2.4-0.7-3.5c-0.5-1-1.3-2-2.4-2.8
        c-1.1-0.8-2.2-1.5-3.2-2.1c-1-0.6-2.5-1.1-4.5-1.7c-2-0.6-3.6-1-4.9-1.3s-3.2-0.7-5.7-1.3c-9-2.1-15.4-4-19.4-5.9
        c-9.5-4.3-14.8-10.9-16-19.9c-0.2-1.4-0.3-2.9-0.3-4.4c0-9.7,3.5-17.1,10.4-22.5c6.9-5.3,16.4-8,28.4-8c12.3,0,21.8,2.9,28.5,8.7
        c6.7,5.8,10.1,13.8,10.1,24h-22.9c0-4.2-1.6-7.3-4.7-9.5c-3.1-2.2-7-3.3-11.6-3.3c-4.1,0-7.7,0.8-10.6,2.5c-3,1.7-4.4,4-4.4,7.2
        c0,1.4,0.4,2.6,1.2,3.8c0.8,1.1,1.7,2.1,2.7,2.8c1,0.7,2.6,1.4,4.7,2.2c2.1,0.7,3.9,1.3,5.4,1.7c1.5,0.4,3.7,0.9,6.6,1.6
        c2.4,0.6,4.3,1,5.6,1.3c1.3,0.3,3.2,0.9,5.6,1.6c2.4,0.7,4.3,1.4,5.7,2c1.4,0.6,3.1,1.4,5.2,2.4c2,1,3.7,2,4.9,3.1
        c1.2,1,2.5,2.3,4,3.8c1.4,1.5,2.5,3.1,3.2,4.8s1.4,3.6,1.9,5.7c0.5,2.2,0.8,4.5,0.8,7c0,5.3-1.1,10.1-3.2,14.3
        c-2.1,4.2-5.1,7.6-8.8,10.3c-3.7,2.6-8,4.6-12.8,6S626.6,205.8,621,205.8z"
      />
    </g>
  </svg>
)
