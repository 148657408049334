import request from 'superagent'
import {v4 as uuid} from 'uuid'

import {TListOptions} from '@/typedef'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {locationsApi, getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TLocation} from '../typedef'

export const saveLocation = async (
  data: TLocation
): Promise<ISuperAgentResSingle<TLocation>> =>
  await request
    .post(locationsApi(uuid()).post)
    .set(getHeadersWithGRPC())
    .send(data)

export const patchLocation = async (
  data: TLocation,
  id?: string
): Promise<ISuperAgentResSingle<TLocation>> =>
  await request
    .patch(locationsApi(id).getOne)
    .set(getHeadersWithGRPC())
    .send(data)

export const fetchLocation = async (id: string) => {
  return await request.get(locationsApi(id).getOne).set(getHeadersWithGRPC())
}

export const getLocations = async (query = {}) => {
  return await request
    .options(locationsApi().get)
    .set(getHeadersWithGRPC())
    .send({
      selector: {...query},
    })
}

export const fetchLocations = async <T extends Record<string, any>>({
  passedBookmark,
  selector,
  sort,
  resolve,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(locationsApi().get + resolve)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...(selector || {}),
      },
      sort,
    })
