import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'

import {Select, Spin} from 'antd'
import {useDispatch} from 'react-redux'
import request from 'superagent'

import {useEffectAfterMount} from '@/hooks/useEffectAfterMount'
import {usePrevious} from '@/hooks/usePrevious'
// import {getHeadersWithGRPC} from '@/services/api'
import {getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'

import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

import FormItemCustom from '../form/form-item-custom'

import {getSortValue} from './utils'
import {
  FETCH_DATA_FAILED,
  FETCH_DATA_REQUESTED,
  FETCH_DATA_SUCCEEDED,
  selectBMReducer,
} from './selectBMReducer'

const Option = Select.Option

export default function SelectWithBM({
  scAuthHeaders = null,
  fetchLoading = false,
  ...props
}) {
  const rdxDispatch = useDispatch()
  const [state, dispatch] = useReducer(selectBMReducer, {
    loading: false,
    numberOfRecord: undefined,
    bookmark: undefined,
    searchText: undefined,
    mountLoading: false,
    data: props.initialValue || [],
  })

  const [refreshKey, setRefreshKey] = useState(9)

  const inputTimer = useRef()
  const selectWrapper = useRef()
  const prevRules = usePrevious(props.rules)
  const prevFilterData = usePrevious(props.filterData)
  const prevInitialValue = usePrevious(props.initialValue)
  const fetchData = useCallback(
    (
      bookmark = undefined,
      selector = undefined,
      sort = getSortValue(props.api)
    ) => {
      if (props.api) {
        dispatch({
          type: FETCH_DATA_REQUESTED,
        })
        let req
        if (props.apiGet) {
          req = request.get(props.api)
        } else {
          req = request.options(props.api)
        }
        req
          .set(scAuthHeaders ? scAuthHeaders : getHeadersWithGRPC())
          .send({bookmark})
          .send({selector: selector ? selector : {}})
          .send({sort: sort ? sort : []})
          .then(res => {
            dispatch({
              type: FETCH_DATA_SUCCEEDED,
              payload: {
                apiResBookmark: res.body.bookmark,
                passedBookmark: bookmark,
                data: res.body.result,
                selector,
              },
            })
          })
          .catch(err => {
            dispatch({
              type: FETCH_DATA_FAILED,
            })
            rdxDispatch(setToastMessage({message: err}))
          })
      }
    },
    [rdxDispatch, props.api, props.apiGet, scAuthHeaders, dispatch]
  )

  const scrollInPopup = e => {
    // antd let you fetch one data twice, if you scrolled fast, now its fixed
    // if select is already loading some data, then do nothing, otherwise fetch
    let loading, numberOfRecord
    if (props.loading) loading = props.loading
    else loading = state.loading

    if (props.numberOfRecord) numberOfRecord = props.numberOfRecord
    else numberOfRecord = state.numberOfRecord

    if (!loading) {
      if (
        e.target.scrollTop * 2 > (3 / 5) * e.target.scrollHeight - 65 &&
        numberOfRecord >= 5
      ) {
        if (props.api) {
          if (state.apiResBookmark) {
            if (props.selector || props.sort) {
              fetchData(state.apiResBookmark, props.selector, props.sort)
            } else {
              fetchData(state.apiResBookmark, state.selector)
            }
          }
        } else {
          props.onFocus()
        }
      }
    }
  }

  const startAction = action => {
    inputTimer.current = setTimeout(() => {
      action()
    }, 500)
  }

  const onSearchFetch = e => {
    const {onFocus, api, searchKey, selector} = props

    clearTimeout(inputTimer.current)

    if (e === '') {
      if (selector) {
        startAction(() =>
          api
            ? fetchData(undefined, selector)
            : onFocus && onFocus(selector, true)
        )
      } else {
        startAction(() =>
          api ? fetchData() : onFocus && onFocus(undefined, true)
        )
      }
    } else if (e && searchKey) {
      let searchSelectorObj = {}

      if (selector) {
        let obj = {}
        obj[searchKey] = {$regex: `(?i)${e}`}
        searchSelectorObj['$and'] = [obj, selector]
      } else {
        searchSelectorObj[searchKey] = {
          $regex: `(?i)${e}`,
        }
      }
      // if e / searched is not empty use filterOptionFetch
      // otherwise proper fetch of all data

      startAction(() =>
        api
          ? fetchData(undefined, searchSelectorObj)
          : onFocus && onFocus(searchSelectorObj, true)
      )
    } else {
      startAction(() => (api ? fetchData() : onFocus && onFocus()))
    }
  }

  const onSelect = e => {
    if (props.onClear) props.onClear(undefined, props.name)
    if (props.onSelect) props.onSelect(e)
  }
  useEffect(() => {
    if (
      (!prevInitialValue || !prevInitialValue.length) &&
      props.initialValue?.length
    ) {
      dispatch({
        type: FETCH_DATA_SUCCEEDED,
        payload: {
          apiResBookmark: undefined,
          passedBookmark: undefined,
          data: props.initialValue,
          ...((props.initialValue.length === 1 &&
            typeof props.initialValue[0] === 'string') ||
          props.fetchOnMount
            ? {mountLoading: true}
            : {}),
        },
      })

      if (props.fetchOnMount) fetchData()
    }
  }, [props.initialValue, prevInitialValue, props.fetchOnMount, fetchData])

  useEffectAfterMount(() => {
    if (
      JSON.stringify(prevRules) !== JSON.stringify(props.rules) &&
      props.recognizeFieldChange
    ) {
      props.recognizeFieldChange()
    }
  }, [props.rules])

  const {
    disabled,
    initialValue,
    rules,
    label,
    name,
    optionContent,
    className,
    mode,
    onChange,
    // filterOption is for case of field
    // which dont have query method on BE done yet
    // will be removed once we have all query methods
    filterOption,
    selector,
    sort,
    searchKey,
    getPopupContainer,
    onSearch,
    onFocus,
    loadingSelect,
    onClear,
    spinner,
    onFocusAction,
    style,
    select_style,
    filterData,
    initialValueMultiple,
    error,
    blockOnFocus,
    fetchOnOpen,
  } = props

  useEffect(() => {
    if (
      prevFilterData &&
      Object.keys(prevFilterData).length &&
      !Object.keys(filterData).length
    )
      setRefreshKey(Math.random())
  }, [filterData, prevFilterData])

  const {loading, mountLoading} = state
  // initial value depended on type of asset
  // first is checked array (multiple choices)
  let initialValueId

  const focusFunctions = e => {
    setAutocompleteNone(e)
    if (onFocus) {
      onFocus()
    } else {
      if (!blockOnFocus && !fetchOnOpen)
        selector || sort ? fetchData(undefined, selector, sort) : fetchData()
    }
  }

  if (initialValue) {
    initialValueId =
      mode === 'multiple'
        ? initialValue.map(initVal =>
            initVal?.id ? initVal?.id : initVal?.uuid
          )
        : initialValue[0]?.id || initialValue[0]?.uuid
  }

  if (initialValueMultiple)
    initialValueId =
      initialValueMultiple && initialValueMultiple.length
        ? initialValueMultiple
        : []

  let filterId = null

  if (filterData) {
    if (filterData.id) filterId = filterData.id
    else if (filterData.uuid) filterId = filterData.uuid
  }

  let data = []
  if (state.data) {
    data = state.data
  } else data = undefined
  if (props.data) data = props.data

  const getOnSearch = () => {
    if (searchKey) {
      if (onSearch) {
        return onSearch
      }
      return e => (filterOption ? null : onSearchFetch(e, undefined))
    }
  }

  const select = (
    <Select
      key={refreshKey}
      ref={selectWrapper}
      loading={spinner ? spinner : false}
      allowClear={!(rules && rules.required)}
      showSearch={!!searchKey}
      mode={mode ? mode : 'default'}
      className={className ? className : ''}
      disabled={disabled}
      defaultValue={filterData?.name}
      e2e-test={name}
      data-testid={name}
      autoComplete="none"
      onFocus={e => focusFunctions(e)}
      onDropdownVisibleChange={open => {
        if (open) {
          onFocusAction && onFocusAction()
          fetchOnOpen &&
            (selector || sort
              ? fetchData(undefined, selector, sort)
              : fetchData())
        }
      }}
      onSearch={getOnSearch()}
      onPopupScroll={e => (filterOption ? null : scrollInPopup(e))}
      onSelect={onSelect}
      onChange={e => {
        let name = ((state.data || []).find(r => r.uuid === e) || {}).name
        let newOnChange = null

        if (e === undefined && !props.filters) {
          if (onClear) {
            newOnChange = onClear(props.name)
          }
        } else if (onChange) {
          if (props.filters) {
            newOnChange = onChange(e, name)
          } else {
            newOnChange = onChange(
              e,
              state.data.find(r => r.uuid === e)
            )
          }
        }

        return newOnChange
      }}
      // fix of moving select on scroll
      getPopupContainer={
        getPopupContainer ? getPopupContainer : e => e.parentNode
      }
      // filterOption={() => true} ensure select to refresh options onSearch
      // otherwise it will not refresh options and become empty instead
      filterOption={filterOption ? filterOption : () => true}
      style={select_style || {}}
      value={filterId}
    >
      {// if not loading, continue with other conditions
      // if the field is not dissabled
      loadingSelect ? (
        <Option value="loading" key="loading">
          <Spin />
        </Option>
      ) : (
        // and data hase been fetched
        <>
          {(data || []).map((dataOne, index) => {
            return (
              <Option
                value={dataOne?.id || dataOne?.uuid || dataOne?.space}
                key={index}
                e2e-test={`${name}-${index}`}
              >
                {optionContent(dataOne)}
              </Option>
            )
          })}
          {loading && (
            <Option value="loading" key="loading">
              <Spin />
            </Option>
          )}
        </>
      )}
    </Select>
  )
  if (!props.filters) {
    return (
      <FormItemCustom
        name={name}
        initialValue={initialValueId}
        rules={rules ? [rules] : []}
        label={label}
        style={style ? {margin: 0} : {}}
        autoComplete="none"
      >
        {error ? (
          <div className="ant-input ant-input-disabled">{error}</div>
        ) : (
          ((fetchLoading || mountLoading) && (
            <Spin className="text-center" />
          )) ||
          select
        )}
      </FormItemCustom>
    )
  } else return select
}
