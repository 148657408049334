import {useCallback, useContext, useEffect} from 'react'

import {useHistory} from 'react-router-dom'
import queryString from 'query-string'

import {
  AccountDefaultContext,
  setAccountDefaultAccId,
  setAccountDefaultError,
  setAccountDefaultOrgId,
  setAccountDefaultOrgName,
  setAccountDefaultProgressStatus,
  setAccountDefaultStep,
  setAccountDefaultSubscriptions,
  setAccountDefaultToken,
} from '@/sites/account-default/account-default-context/account-default-context'
import {getBEndToken} from '@/api/token-request'
import {getMyAccountsTS} from '@/api/account-requests'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {createUuidChannelName} from '@/utils/create-uuid-channel-name'
import {
  fetchMySpacesTS,
  getOrgForAccountTS,
} from '@/modules/ITSM/api/itsm-org-requests'
import {fetchSubscriptions} from '@/api/billing-requests'
import {
  AccountCreateStep,
  AccountDefaultError,
  PaymentStatus,
  TAccountDefaultQuery,
  TPrefillCreateAccountData,
} from '@/sites/account-default/typedef'
import {generateConfig} from '@/generateConfig'
import {hasPaymentDetails} from '@/sites/account-default/utils/has-payment-details'

import {useCreateAccount} from '@/sites/account-default/hooks/use-create-account'
import {
  defineStepProgress,
  defineStepProgressSteps,
} from '@/sites/account-default/constants/define-step-progress'
import {useAccountDefaultRedirect} from '@/sites/account-default/hooks/use-account-default-redirect'
import {useSelector} from 'react-redux'
import {selectAccessToken} from '@/redux/user/user-selector'

export const useDefineAccountDefaultStep = () => {
  const history = useHistory()
  const query = queryString.parse(
    history.location.search
  ) as TPrefillCreateAccountData & TAccountDefaultQuery
  const {
    company,
    'first-name': name,
    'last-name': lastName,
    email,
    'phone-number': phone,
    'payment-status': paymentStatus,
  } = query
  const {hasPayment: hasPaymentStep} = hasPaymentDetails(query)

  const {dispatch} = useContext(AccountDefaultContext)
  const {createAccount} = useCreateAccount()

  const redirect = useAccountDefaultRedirect()
  const accessToken = useSelector(selectAccessToken)
  
  const checkStep = useCallback(async () => {
    try {
      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.INITIAL]
        )
      )
      // const responseInitialToken = await getBEndToken()
      
      // dispatch(setAccountDefaultToken(responseInitialToken.body.access_token))
      dispatch(setAccountDefaultToken(accessToken))

      const responseMyAccounts = await getMyAccountsTS(
        // createAuthorizationHeader(responseInitialToken?.body.access_token)
        createAuthorizationHeader(accessToken)
      )

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.MY_ACCOUNTS]
        )
      )

      const account = responseMyAccounts.body[0]

      if (
        generateConfig().FEATURE_FLAGS.enableAccountAutoCreate &&
        !hasPaymentStep &&
        company &&
        name &&
        lastName &&
        email &&
        phone
      ) {
        dispatch(setAccountDefaultProgressStatus(100))

        await createAccount({
          account: `${company}-account`,
          companyName: company,
          channel_name: createUuidChannelName(company),
          email,
          phone,
          firstname: name,
          surname: lastName,
        })

        return redirect(query)
      }

      if (!account) {
        return dispatch(setAccountDefaultStep(AccountCreateStep.CREATE_ACCOUNT))
      }

      dispatch(setAccountDefaultAccId(account.id))

      // const responseAccToken = await getBEndToken({
      //   id: account.id,
      //   scope: 'acc',
      // })

      // dispatch(setAccountDefaultToken(responseAccToken.body.access_token))
      dispatch(setAccountDefaultToken(accessToken))

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.REFRESH_ACC_TOKEN]
        )
      )

      const responseMyOrgs = await getOrgForAccountTS({
        id: account.id,
        // headers: createAuthorizationHeader(responseAccToken.body.access_token),
        headers: createAuthorizationHeader(accessToken),
      })

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.MY_ORGS]
        )
      )

      const org = responseMyOrgs.body[0]
      if (!org) {
        return dispatch(
          setAccountDefaultError(new Error(AccountDefaultError.NO_ORG))
        )
      }

      dispatch(setAccountDefaultOrgId(org.id))
      dispatch(setAccountDefaultOrgName(org.name))

      const responseOrgToken = await getBEndToken({
        id: org.id,
      })

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.REFRESH_ORG_TOKEN]
        )
      )

      if (!responseOrgToken?.body.access_token) {
        return dispatch(
          setAccountDefaultError({
            message: 'no access token with organization',
          })
        )
      }

      dispatch(setAccountDefaultToken(responseOrgToken.body.access_token))
      const responseMySpaces = await fetchMySpacesTS(
        createAuthorizationHeader(responseOrgToken?.body.access_token)
      )
      
      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.MY_SPACES]
        )
      )

      if (responseMySpaces.body.spaces.length === 0) {
        return dispatch(
          setAccountDefaultError(new Error(AccountDefaultError.NO_SPACE))
        )
      }
      
      const subscriptions = await fetchSubscriptions(
        responseOrgToken?.body.access_token
      )

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.MY_SUBSCRIPTIONS]
        )
      )

      const hasSubscription = subscriptions.body.result.length > 0

      if (!hasSubscription && !hasPaymentStep) {
        return dispatch(
          setAccountDefaultError(new Error(AccountDefaultError.NO_SUBSCRIPTION))
        )
      }

      if (!hasSubscription && hasPaymentStep) {
        return dispatch(setAccountDefaultStep(AccountCreateStep.PAYMENT))
      }

      if (
        paymentStatus === PaymentStatus.SUCCESS &&
        generateConfig().FEATURE_FLAGS.isCalendlyBookEnabled
      ) {
        dispatch(setAccountDefaultSubscriptions(subscriptions.body.result))

        return dispatch(
          setAccountDefaultStep(AccountCreateStep.SCHEDULE_CALENDLY)
        )
      }

      dispatch(
        setAccountDefaultProgressStatus(
          defineStepProgress[defineStepProgressSteps.REDIRECT]
        )
      )

      return redirect(query)
    } catch (error) {
      dispatch(setAccountDefaultError(error))
    }
  }, [
    dispatch,
    hasPaymentStep,
    paymentStatus,
    company,
    name,
    lastName,
    email,
    phone,
  ])

  useEffect(() => {
    checkStep()
  }, [checkStep])
}
