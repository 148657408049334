// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup-call-step .calendly-inline-widget {
  height: 950px !important;
}

.setup-call-step .setup-call-step__button {
  width: 100%;
}

@media (min-width: 992px) {
  .setup-call-step .calendly-inline-widget {
    height: 750px !important;
  }
  .setup-call-step .setup-call-step__button {
    width: unset;
    margin-left: calc(120 / 16 * 1rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/sites/account-default/components/setup-call-step.scss","webpack://./src/styles/_media-queries.scss"],"names":[],"mappings":"AAGA;EACE,wBAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;ACoBI;EDdF;IACE,wBAAA;EAFF;EAKA;IACE,YAAA;IACA,kCAAA;EAHF;AACF","sourcesContent":["@import '~mixins';\n@import '~mediaQueries';\n\n.setup-call-step .calendly-inline-widget {\n  height: 950px!important;\n}\n\n.setup-call-step .setup-call-step__button {\n  width: 100%;\n}\n\n@include respond-above(md) {\n  .setup-call-step .calendly-inline-widget {\n    height: 750px!important;\n  }\n\n  .setup-call-step .setup-call-step__button {\n    width: unset;\n    margin-left: toRem(120);\n  }\n}","//\n//  MEDIA QUERIES\n//––––––––––––––––––––––––––––––––––––––––––––––––––\n\n// A map of breakpoints.\n$breakpoints: (\n  xs: 576px,\n  sm: 768px,\n  md: 992px,\n  lg: 1200px,\n  xl: 1600px,\n  xxl: 2100px,\n);\n\n//\n//  RESPOND ABOVE\n//––––––––––––––––––––––––––––––––––––––––––––––––––\n\n// @include respond-above(sm) {}\n@mixin respond-above($breakpoint) {\n  // If the breakpoint exists in the map.\n  @if map-has-key($breakpoints, $breakpoint) {\n    // Get the breakpoint value.\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    // Write the media query.\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n\n    // If the breakpoint doesn't exist in the map.\n  } @else {\n    // Log a warning.\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
