import React from 'react'

import {useSelector} from 'react-redux'
import {LogoutOutlined} from '@ant-design/icons'
import {Avatar, Divider} from 'antd'

import {Modules} from '@/typedef'
import {
  selectModuleName,
  selectUserEmail,
  selectUserName,
  selectUserRoleType,
} from '@/redux/user/userSelector'
import {translate} from '@/services/i18n'
import {Dropdown} from '@/components/dropdown'
import {useFeatureAllowed} from '@/hooks/use-feature-allowed'
import {isUserManagerRole} from '@/modules/ITSM/utils/isUserManagerRole'
import {useIsStripeFeatureAllowed} from '@/hooks/use-stripe-feature-flags'

import {PriceFeatureFlagKeys} from '@/modules/typedef'

import './user-menu.scss'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {INVITATION_MAP} from './constants'

const createUserAbbr = (user: string | null) =>
  user ? user.split(' ').map(part => part.slice(0, 1).toUpperCase()) : null

type TProps = {
  handleClick: (redirectTo: string) => void
  handleLogout: () => void
}

export const UserMenu: React.FC<TProps> = ({handleClick, handleLogout}) => {
  const moduleName: Modules = useSelector(selectModuleName)
  const {isRouteAllowedByUserRole} = useFeatureAllowed()
  const {isFeatureAllowed} = useIsStripeFeatureAllowed()
  const isManager = isUserManagerRole(useSelector(selectUserRoleType))
  const userName = useSelector(selectUserName)
  const userEmail = useSelector(selectUserEmail)

  const userAbbreviation = createUserAbbr(userName)

  const avatarIcon = !userAbbreviation ? (
    <LogoutOutlined className="user-menu__avatar-icon" />
  ) : null

  const {userInvURl, orgInvURl} = INVITATION_MAP[moduleName] || {}

  const menuItems = [
    {
      key: userName,
      label: <div className="user_menu-name">{userName}</div>,
    },
    {
      key: userEmail,
      label: <div className="user_menu-email">{userEmail}</div>,
    },
    {
      key: 'divider-1',
      label: <Divider className="mt-4 mb-4" />,
    },
    {
      key: 'profile',
      label: <div>{translate('profile')}</div>,
      onClick: () => handleClick(itsmRoutes.settingsITSMUser.path()),
    },

    ...((userInvURl?.type && isRouteAllowedByUserRole(userInvURl?.type)) ||
    (isManager && userInvURl?.type)
      ? [
          {
            key: userInvURl?.path,
            label: (
              <div
                onClick={() => handleClick(userInvURl?.path)}
                e2e-test="invite_user"
              >
                {translate('invite_user')}
              </div>
            ),
          },
        ]
      : []),
    ...(isFeatureAllowed(PriceFeatureFlagKeys.CUSTOMER_PRODUCT) &&
    orgInvURl?.type &&
    isRouteAllowedByUserRole(orgInvURl?.type)
      ? [
          {
            key: orgInvURl?.path,
            label: (
              <div
                onClick={() => handleClick(orgInvURl?.path)}
                e2e-test="invite_org"
              >
                {translate('invite_org')}
              </div>
            ),
          },
        ]
      : []),
    {
      key: 'divider-2',
      label: <Divider className="mt-4 mb-4" />,
    },
    {
      key: 'logout',
      label: (
        <div onClick={handleLogout}>
          {translate('logout')}&nbsp;
          <LogoutOutlined className="user-menu_logout-icon" />
        </div>
      ),
    },
  ]

  return (
    <div className="user-menu" data-testid="user-menu">
      <Dropdown
        menu={{items: menuItems}}
        trigger={['click']}
        placement={'bottomRight'}
        getPopupContainer={e => e.parentNode as HTMLElement}
      >
        <a
          className="user-menu__link"
          data-testid="user_menu_button"
        >
          <Avatar
            icon={avatarIcon}
            shape="circle"
            className="user-menu__avatar"
            size="large"
          >
            <b>{userAbbreviation}</b>
          </Avatar>
        </a>
      </Dropdown>
    </div>
  )
}

export default UserMenu
