// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker-dropdown .ant-picker-panel-container {
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/date-picker/date-picker.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".ant-picker-dropdown .ant-picker-panel-container {\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
