// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-default-error-modal.ant-modal .ant-modal-footer {
  padding-top: 0;
}

.account-default-error-modal .ant-modal-title {
  text-align: center;
}

.ant-modal-body {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/sites/account-default/components/account-default-error-modal.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".account-default-error-modal.ant-modal .ant-modal-footer {\n  padding-top: 0;\n}\n\n.account-default-error-modal .ant-modal-title {\n  text-align: center;\n}\n\n.ant-modal-body {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
