// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-reports-row {
  padding-bottom: calc(16 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/sites/CustomReports/custom-reports.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;AADF","sourcesContent":["@import '~mixins';\n\n.custom-reports-row {\n  padding-bottom: toRem(16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
