import request from 'superagent'

// import {getHeadersWithGRPC} from '@/services/api'
import {
  documents,
  documentStorageApi,
  getHeadersWithGRPC
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getDocuments = async (channel: string) => {
  return await request
    .get(documents(channel).list + '?param=' + new Date().getTime())
    .set(getHeadersWithGRPC())
}

export const getDocument = async (file: string, channel: string) => {
  return await request
    .get(documents(channel).getOne(file))
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const postConfirmReading = async (data: Record<string, unknown>) => {
  return await request
    .post(documents().docsRead)
    .send(data)
    .set(getHeadersWithGRPC())
}

export const fetchDocs = async () => {
  return await request.get(documentStorageApi().get).set(getHeadersWithGRPC())
}

export const postDoc = async (
  fileData: Record<string, any>,
  queryParams?: string
) => {
  const {data, name} = fileData
  return await request
    .post(documentStorageApi().post(name, queryParams))
    .send(data)
    .set(getHeadersWithGRPC())
}

export const getDoc = async (id: string) => {
  return await request
    .get(documentStorageApi().download(id))
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const deleteDoc = async (id: string) => {
  return await request
    .post(documentStorageApi().delete(id))
    .set(getHeadersWithGRPC())
}
