import request from 'superagent'

import {token4itsmApi, grantApi} from '@/services/api'
import {TAuthResponse, THeaders, TTokenReqPayload} from '@/api/typedf'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {generateConfig} from '@/generateConfig'
import store from '@/redux/store'
import { useAuth0 as useAuth } from '@auth0/auth0-react';
import { flatMap } from 'lodash'

export const getBEndToken = async (
  payload?: TTokenReqPayload
): Promise<ISuperAgentResponse<TAuthResponse>> => {
  const {id, scope = 'org'} = payload || {}
  const {accessToken, backEndToken} = store.getState().itsmUser

  const blits_backend_token_override = localStorage.getItem(
    'blits_backend_token_overrided'
  )
  if (blits_backend_token_override)
    return Promise.resolve({
      body: {
        access_token: blits_backend_token_override,
        issued_token_type: 'urn:ietf:params:oauth:token-type:jwt',
        token_type: 'Bearer',
        expires_in: 3600,
        scope: ``,
      },
    })
  
  let orgID = id
  if ((typeof id === 'undefined') || id == '')
    orgID = "1848e8b6-4fac-4991-a361-51a929a89aa2"

  return request
    .post(token4itsmApi().getToken)
    .set({
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    })
    .send({
      org: orgID,
      acc: 'accacc',
    })

  // return Promise.resolve({
  //   body: {
  //     access_token: 'joeTokenssssws',
  //     issued_token_type: 'urn:ietf:params:oauth:token-type:jwt',
  //     token_type: 'Bearer',
  //     expires_in: 3600,
  //     scope: ``,
  //   },
  // })

  // return request
  //   .post(authentication().getStsToken)
  //   .set({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   })
  //   .send({
  //     subject_token: accessToken,
  //     ...(id && {
  //       scope: `urn:x-blits:scope:${scope}:${id}`,
  //     }),
  //     grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
  //     subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
  //     audience: generateConfig().STS_AUDIENCE,
  //   })
}

export const postAcceptInvite = ({
  grantToken,
  headers,
}: {
  grantToken: string
  headers: THeaders
}): Promise<ISuperAgentResponse<undefined>> => {
  return request
    .post(grantApi.post)
    .set(headers)
    .send({grantToken})
}
