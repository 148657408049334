import React, {useState} from 'react'

import {Form as AntdForm} from 'antd'
import {useHistory} from 'react-router-dom'

import {ChannelForm} from '@/modules/ITSM/components/channel-form/channel-form'
import {checkIsLoading} from '@/utils/check-is-loading'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {TItsmChannel} from '@/modules/ITSM/typedef'
import {Paper, PaperVariant} from '@/components/paper/paper'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {translate} from '@/services/i18n'
import {useApiRequestWithRetry} from '@/hooks/use-api-request-with-retry'
import {postNewChannel} from '@/modules/ITSM/api/ts-version/post-new-channel'
import {createChannelExistErrorMessage} from '@/utils/create-channel-exist-error-message'
import store from '@/redux/store'

const ChannelNew = () => {
  const history = useHistory()
  const [form] = AntdForm.useForm()
  const [isDisplayModal, setIsDisplayModal] = useState(false)

  const {
    run: postChannel,
    status: postChannelStatus,
    error: postChannelError,
  } = useApiRequestWithRetry(postNewChannel)

  const handleCreateChannel = async ({name}: TItsmChannel) => {
    const {accessToken} = store.getState().itsmUser
    const response = await postChannel({
      data: {token_back: accessToken, channel_name: name},
    })

    if (response.body?.result) {
      return history.push(itsmRoutes.settingsITSMChannels.path())
    }

    return setIsDisplayModal(true)
  }

  return (
    <Paper variant={PaperVariant.form}>
      <Modal
        info={createChannelExistErrorMessage(postChannelError)}
        open={isDisplayModal}
        okText={translate('ok')}
        handleAccept={() => setIsDisplayModal(false)}
        onCancel={() => setIsDisplayModal(false)}
        modalType={ModalType.WARNING}
        maskClosable
      />
      <ChannelForm
        handleSubmit={handleCreateChannel}
        form={form}
        isLoading={checkIsLoading(postChannelStatus)}
      />
    </Paper>
  )
}

export default ChannelNew
