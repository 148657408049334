import request from 'superagent'

import {getHeaders, identityApi} from '@/modules/SAAS/api/generate-saas-api-url'
import {ISaasIdentityProvider, TSaasIdentityMe} from '@/modules/SAAS/typedf'
import {ISuperAgentResSingle} from '@/api/response-typedf'
// import {getBackendTokenHeaders} from '@/services/api'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'

export const fetchSaasIdentityMe = async (): Promise<{
  body: TSaasIdentityMe
}> => {
  return await request.get(identityApi().get).set(getToken())
}

export const fetchSaasIdentityProvider = async (): Promise<ISuperAgentResSingle<
  ISaasIdentityProvider
>> => {
  return await request.get(identityApi().provider).set(getHeaders())
}

export const createSaasIdentityProvider = async (
  payload: Omit<ISaasIdentityProvider, 'id'>
) => {
  return await request
    .post(identityApi().provider)
    .set(getHeaders())
    .send(payload)
}

export const updateSaasIdentityProvider = async (
  payload: Omit<ISaasIdentityProvider, 'id'>
) => {
  return await request
    .patch(identityApi().provider)
    .set(getHeaders())
    .send(payload)
}
