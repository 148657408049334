import request from 'superagent'

// import {getHeadersWithGRPC} from '@/services/api'
import {TListOptions} from '@/typedef'
import {
  ISuperAgentResMultiple,
  ISuperAgentResponse,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {identitiesApi, userApi, getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'
import {THeaders} from '@/api/typedf'
import {TIdentityMeResponse} from '@/modules/ITSM/api/typedef'
import {TIdentityReq} from '@/modules/ITSM/components/identity-form/typedf'

export const fetchUser = async <T extends Record<string, any>>(
  id: string,
  headers?: Record<string, any>,
  resolve = ''
): Promise<ISuperAgentResSingle<T>> => {
  return await request
    .get(userApi(id).getOne + resolve)
    .set(headers || getHeadersWithGRPC())
}

export const fetchUsers = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
  fields,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(userApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
      ...(fields ? {fields} : {}),
    })

export const postUser = async (data: Record<string, any>) => {
  return await request
    .post(userApi().createNew)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postUserWithHeaders = async ({
  payload,
  headers,
}: {
  payload: TIdentityReq
  headers: THeaders
}) =>
  await request
    .post(userApi().createNew)
    .set(headers)
    .send(payload)

export const patchUser = async (uuid: string, data: Record<string, any>) => {
  return await request
    .patch(userApi(uuid).getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchSuppliers = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(userApi().getSuppliers() + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const postIdentityMe = async (
  headers: THeaders
): Promise<ISuperAgentResponse<TIdentityMeResponse>> =>
  await request.post(identitiesApi().post).set(headers)
