import {call, put, select, delay} from 'redux-saga/effects'

import {fetchCustomer} from '@/api/billing-requests'
import {
  fetchCustomerSucceeded,
  setTransactionCounter,
  setBalanceTransaction,
  setRefetchDelay,
  fetchCustomerRequested,
} from '@/redux/billing/billing-action-creators'
import {selectModuleProfile} from '@/redux/profile/profileSelector'
import {getBEndToken} from '@/api/token-request'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {
  selectTransactionCounter,
  selectTransactionAmount,
  selectRefetchDelay,
} from '@/redux/billing/billing-selector'

export function* getBillingCustomerSaga({payload: {balance}}) {
  const {orgId: id} = yield select(selectModuleProfile)
  const transactionAmount = yield select(selectTransactionAmount)
  const fetchCount = yield select(selectTransactionCounter)
  yield put(setRefetchDelay())

  try {
    const {
      body: {access_token},
    } = yield call(getBEndToken, {
      id,
      scope: 'acc',
    })

    const {
      body: {result},
    } = yield call(fetchCustomer, access_token)
    yield put(fetchCustomerSucceeded(result))

    if (
      fetchCount > 0 &&
      result.balance !== parseInt(balance) + transactionAmount
    ) {
      const delayTime = yield select(selectRefetchDelay)
      yield put(setTransactionCounter(fetchCount - 1))
      yield delay(delayTime)
      yield put(fetchCustomerRequested(balance))
    }
    if (result.balance === parseInt(balance) + transactionAmount) {
      yield put(setBalanceTransaction(0))
      yield put(setTransactionCounter(0))
    }
  } catch (err) {
    yield put(
      setToastMessage({
        message: err,
      })
    )
  }
}
