// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incident-task-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.incident-task-btns .ant-btn {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/incident-task/incident-task-btns/incident-task-btns.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".incident-task-btns {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .ant-btn {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
