import request from 'superagent'

import {TCreateChannel} from '@/components/accountForm/types'
import {channelApi, /*getBackendTokenHeaders*/} from '@/services/api'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {TChannelResponse} from '../../typedef'
import {THeaders} from '@/api/typedf'

export const postNewChannel = async ({
  data,
  headers,
}: {
  data: TCreateChannel
  headers?: THeaders
}): Promise<ISuperAgentResponse<TChannelResponse>> => {
  return await request
    .post(channelApi().postCreateNewChannel)
    .set(headers || getToken())
    .send(data)
}
