import {generateConfig} from '@/generateConfig'

import store from '../redux/store'

const apiUrl = generateConfig().API_IDENTITY_URL
const apiUrlITSM = generateConfig().API_ITSM_URL

const stsTokenUrl = generateConfig().STS_TOKEN_URL

const cmdbUrl = `${generateConfig().API_URL_CMDB}/assets`

const apiACUrl = generateConfig().API_URL_AC

export function getCmdbHeaders() {
  const {backEndToken} = store.getState().itsmUser
  const {cmdbNamespaceId} = store.getState().entries

  return {
    ...(cmdbNamespaceId ? {space: cmdbNamespaceId} : {}),
    Authorization: `Bearer ${backEndToken}`,
  }
}

export const getBackendTokenHeaders = () => {
  // const {backEndToken} = store.getState().itsmUser  Nikola
  const {accessToken} = store.getState().itsmUser
  return {
    Authorization: `Bearer ${accessToken}`,
  }
}

export const getBackendToken2Headers = () => {
  const {accessToken} = store.getState().itsmUser
  return {
    Token2: `Bearer ${accessToken}`,
  }
}

export function getHeadersWithGRPC() {
  const {channelID} = store.getState().entries

  const headers = getBackendTokenHeaders()

  return {
    'Grpc-Metadata-space': channelID,
    ...headers,
  }
}

export const generateAuthHeaders = accessToken => {
  return {
    Authorization: `Bearer ${accessToken}`,
  }
}

export const getToken = () => {
  const {backEndToken} = store.getState().itsmUser
  return `Bearer ${backEndToken}`
}

export const getGrpcMetaSpace = () => {
  const {channelID} = store.getState().entries
  return channelID
}

export const authentication = () => {
  return {
    getStsToken: stsTokenUrl,
  }
}

export const profileApi = () => {
  return {
    get: apiUrl + '/profile',
    post: apiUrl + '/profile',
  }
}

export const token4itsmApi = () => {
  return {
    getToken: apiUrl + '/token4itsm',
  }
}

// GENERAL

export const organizationApi = {
  getForAccount: accountId => apiUrl + '/accounts/' + accountId + '/orgs',
  getOrg: apiUrl + '/orgs',
}

export const grantApi = {
  post: apiUrl + '/provisionings:acceptInvite',
}

export const channelApi = () => {
  return {
    post: `${apiUrlITSM}/apps-orchestrator-be${
      generateConfig().API_ITSM_PATH_PREFIX
    }${generateConfig().API_ITSM_V1}/app/create-channel`,
    upgrade: `${apiUrlITSM}/apps-orchestrator-be${
      generateConfig().API_ITSM_PATH_PREFIX
    }${generateConfig().API_ITSM_V1}/app/upgrade-chaincode`,
    patchChannel: `${apiUrlITSM}${generateConfig().API_ITSM_PATH_PREFIX}${
      generateConfig().API_ITSM_V1
    }/singletons/settings`,
    postCreateNewChannel: `${apiUrlITSM}${
      generateConfig().API_ITSM_PATH_PREFIX
    }${generateConfig().API_ITSM_V1}/init`,
  }
}

export const googleMapApi = () => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${
    generateConfig().GOOGLE_MAP_KEY
  }&language=en`
  return {
    address: (address, options = {}) => {
      const optionsValue = Object.keys(options).reduce(
        (acc, key) => `${acc}&${key}=${options[key]}`,
        ''
      )
      return `${url}&address=${address}${optionsValue}`
    },
    coordinates: ({lat, lng}) => `${url}&latlng=${lat},${lng}`,
  }
}

export const assetsApi = () => {
  return {
    get: cmdbUrl,
  }
}

export const peerApi = () => {
  return {
    get: apiUrl + '/api/v1/fabric/peers',
  }
}

export const ordererApi = () => {
  return {
    get: apiUrl + '/api/v1/fabric/orderers',
  }
}

export const activeCampaignApi = {
  patch: apiACUrl,
}
