import React, {useEffect} from 'react'

import {Form, message as antdMessage} from 'antd'

import {useFetchHook} from '@/hooks/useFetch'
import {scrollToField} from '@/utils/scroll-to-field'

import {TCustomerLocationRecord} from '../../typedef'

import {LocationForm} from './location-form'

export const CreateNewLocation: React.FC<TCustomerLocationRecord> = ({
  record,
  isVisible,
  onClose,
  onSave,
  formRules,
  withoutSave = false,
  preSaveAction,
  asyncF,
}) => {
  const [form] = Form.useForm()

  const {
    status: saveStatus,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })
  useEffect(() => {
    if (!isVisible) {
      if (form) {
        form.resetFields()
      }
    }
  }, [form, isVisible])

  const handleSubmit = async () => {
    try {
      const formData = await form.validateFields()
      try {
        handleRequested()
        if (!withoutSave) {
          if (preSaveAction) preSaveAction()

          const {
            body: {result},
          } = await asyncF(formData)
          if (result) onSave && onSave(result)
        } else onSave && onSave(formData)
        handleSuccess()

        if (onClose) {
          onClose()
        }
      } catch (err) {
        const {message} = err as Error
        antdMessage.destroy()
        if (!message.includes('permission denied')) {
          handleFail(err)
        }
      }
    } catch (errorFields) {
      if (errorFields) {
        scrollToField({
          // @ts-ignore Type 'unknown' is not assignable to type '{ errorFields: { name: InternalNamePath; errors: string[]; }[]; }'
          errorFields,
          form,
        })
      }
    } finally {
      handleReset()
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <LocationForm
      rules={formRules}
      initialValues={record}
      handleSubmit={handleSubmit}
      saveStatus={saveStatus}
      handleClose={handleClose}
      form={form}
    />
  )
}
