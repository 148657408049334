import request from 'superagent'

import {itsmOrganizationApi} from '@/modules/ITSM/api/generate-itsm-api-url'
// import {getBackendTokenHeaders} from '@/services/api'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {
  TPostOrganizationPayload,
  TPostOrganizationResponse,
} from '@/modules/ITSM/api/typedef'

export const postOrganization = async ({
  data,
  accId,
  headers,
}: TPostOrganizationPayload): Promise<ISuperAgentResponse<
  TPostOrganizationResponse
>> =>
  request
    .post(itsmOrganizationApi().create(accId))
    .set(headers || getToken())
    .send(data)
