// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories-table .ant-table-expanded-row .ant-table-thead {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/sites/ITSMSettings/Categories/categories.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ","sourcesContent":[".categories-table .ant-table-expanded-row {\n  .ant-table-thead {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
