// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
}
.error-page__icon {
  font-size: calc(100 / 16 * 1rem);
}
.error-page button {
  margin-top: calc(15 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/components/error/error-page/error-page.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AADF;AAGE;EACE,gCAAA;AADJ;AAIE;EACE,gCAAA;AAFJ","sourcesContent":["@import '~mixins';\n\n.error-page {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 70vh;\n  width: 100%;\n\n  &__icon {\n    font-size: toRem(100);\n  }\n\n  button {\n    margin-top: toRem(15);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
