// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logo-wrapper__logo {
  width: calc(126 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/components/logo-wrapper/logo-wrapper.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AADF;AAEE;EACE,4BAAA;AAAJ","sourcesContent":["@import '~mixins';\n\n.logo-wrapper {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  &__logo {\n    width: toRem(126);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
