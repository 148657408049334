// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.identities__roles {
  font-size: calc(12 / 16 * 1rem);
}

.itsm-identities-role .ant-drawer-title {
  padding: 0;
}
.itsm-identities-role .ant-transfer {
  padding-top: calc(4 / 16 * 1rem);
  width: 100%;
}
.itsm-identities-role .ant-input-number {
  width: 100%;
}
.itsm-identities-role .drawer-footer.ant-layout-footer {
  padding: calc(15 / 16 * 1rem) 0 0 0;
  position: relative;
}

.itsm-identities-role .ant-transfer .ant-transfer-list {
  width: 40%;
  height: calc(300 / 16 * 1rem);
}

.itsm-identities-role .ant-transfer .ant-transfer-operation {
  width: 20%;
  padding: calc(8 / 16 * 1rem);
  display: inline-grid;
  margin: 0;
}

.itsm-identities-role .ant-transfer .ant-transfer-operation .ant-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itsm-identities-role .ant-transfer .ant-transfer-operation .ant-btn:first-child {
  flex-direction: row-reverse;
}

.itsm-identities-role .ant-form-item {
  margin-bottom: calc(20 / 16 * 1rem);
}

.itsm-identities-role .ant-form-item .ant-form-item-label {
  font-size: calc(15 / 16 * 1rem);
}

.itsm-identities-role .ant-form-item .ant-form-item-control {
  line-height: calc(20 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/sites/ITSMSettings/users/IdentitiesDrawer.scss"],"names":[],"mappings":"AAGE;EACE,+BAAA;AAFJ;;AAOE;EACE,UAAA;AAJJ;AAME;EACE,gCAAA;EACA,WAAA;AAJJ;AAME;EACE,WAAA;AAJJ;AAME;EACE,mCAAA;EACA,kBAAA;AAJJ;;AAQA;EACE,UAAA;EACA,6BAAA;AALF;;AAQA;EACE,UAAA;EACA,4BAAA;EACA,oBAAA;EACA,SAAA;AALF;;AAQA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALF;;AAQA;EAIE,2BAAA;AARF;;AAWA;EACE,mCAAA;AARF;;AAWA;EACE,+BAAA;AARF;;AAWA;EACE,iCAAA;AARF","sourcesContent":["@import '~mixins';\n\n.identities {\n  &__roles {\n    font-size: toRem(12);\n  }\n}\n\n.itsm-identities-role {\n  .ant-drawer-title {\n    padding: 0;\n  }\n  .ant-transfer {\n    padding-top: toRem(4);\n    width: 100%;\n  }\n  .ant-input-number {\n    width: 100%;\n  }\n  .drawer-footer.ant-layout-footer {\n    padding: toRem(15) 0 0 0;\n    position: relative;\n  }\n}\n\n.itsm-identities-role .ant-transfer .ant-transfer-list {\n  width: 40%;\n  height: toRem(300);\n}\n\n.itsm-identities-role .ant-transfer .ant-transfer-operation {\n  width: 20%;\n  padding: toRem(8);\n  display: inline-grid;\n  margin: 0;\n}\n\n.itsm-identities-role .ant-transfer .ant-transfer-operation .ant-btn {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.itsm-identities-role\n  .ant-transfer\n  .ant-transfer-operation\n  .ant-btn:first-child {\n  flex-direction: row-reverse;\n}\n\n.itsm-identities-role .ant-form-item {\n  margin-bottom: toRem(20);\n}\n\n.itsm-identities-role .ant-form-item .ant-form-item-label {\n  font-size: toRem(15);\n}\n\n.itsm-identities-role .ant-form-item .ant-form-item-control {\n  line-height: toRem(20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
