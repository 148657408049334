import request from 'superagent'

import {TListOptions} from '@/typedef'
import {
  ISuperAgentResMultiple,
  ISuperAgentResponse,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {productApi, getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TCustomerProduct, TSupplierProduct} from '../typedef'

export const fetchCustomerProduct = async ({
  selector,
}: {
  selector: Record<string, unknown>
}) => {
  return await request
    .options(productApi().customer)
    .set(getHeadersWithGRPC())
    .send({...(selector ? {selector} : {})})
}

export const fetchOneCustomerProduct = async <T extends Record<string, any>>({
  id,
}: {
  id: string
}): Promise<ISuperAgentResponse<T>> => {
  return await request.get(productApi(id).getOne).set(getHeadersWithGRPC())
}

export const fetchSupplierProduct = async <T extends Record<string, any>>({
  id,
}: {
  id: string
}): Promise<ISuperAgentResponse<T>> => {
  return await request
    .get(productApi(id).supplierGetOne)
    .set(getHeadersWithGRPC())
}

export const patchSupplier = async (
  data: TSupplierProduct,
  uuid: string
): Promise<ISuperAgentResSingle<TSupplierProduct>> => {
  return await request
    .patch(productApi(uuid).patchSupplier)
    .set(getHeadersWithGRPC())
    .send(data)
}
export const patchCustomerProduct = async (
  data: TCustomerProduct,
  uuid: string
): Promise<ISuperAgentResSingle<TCustomerProduct>> => {
  return await request
    .patch(productApi(uuid).patchCustomerProduct)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postCustomerProduct = async (
  data: TCustomerProduct,
  uuid?: string
) => {
  return await request
    .post(productApi(uuid).postCustomerProduct)
    .set(getHeadersWithGRPC())
    .send(data)
}
export const postSupplierProduct = async (
  data: TSupplierProduct,
  uuid?: string
) => {
  return await request
    .post(productApi(uuid).postSupplierProduct)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchSupplierProducts = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(productApi().supplier + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const fetchCustomerProducts = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(productApi().customer + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
