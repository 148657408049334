import request from 'superagent'

import {getBackendTokenHeaders, grantApi, organizationApi} from '@/services/api'
import {accountApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {getToken} from '@/services/api'

export const getOrgAccount = async id => {
  return await request.get(accountApi(id).getOne).set(getBackendTokenHeaders())
}

export const getAccounts = async myAccounts => {
  const endpoint = myAccounts ? accountApi().get : accountApi().getAll

  return await request.get(endpoint).set(getBackendTokenHeaders())
}

export const getOrgs = async id => {
  return await request
    .get(organizationApi.getForAccount(id))
    .set(getBackendTokenHeaders())
}

export const postGrant = async (grant, auth) =>
  await request
    .post(grantApi.post)
    .set(auth)
    .send({grantToken: grant})
