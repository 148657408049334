// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itsm-chatroom {
  margin-top: calc(30 / 16 * 1rem);
}
.itsm-chatroom .ant-card-body {
  padding: calc(5 / 16 * 1rem) calc(20 / 16 * 1rem);
}
.itsm-chatroom .ant-card-body .ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.itsm-chatroom .ant-card-body .ant-list-item-meta-avatar {
  margin-right: calc(16 / 16 * 1rem);
}
.itsm-chatroom .ant-card-body .ant-list-item-meta-title {
  word-break: break-word;
  margin-bottom: calc(4 / 16 * 1rem);
  color: rgba(0, 0, 0, 0.85);
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.5715;
}
.itsm-chatroom .ant-card-body .ant-list-item-meta-title p {
  margin-bottom: 0;
}
.itsm-chatroom .ant-card-body .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.5715;
}
.itsm-chatroom__avatar {
  vertical-align: middle;
}
.itsm-chatroom__title {
  display: flex;
  justify-content: space-between;
}
.itsm-chatroom__read-by {
  font-weight: 400;
  cursor: pointer;
  font-size: calc(12 / 16 * 1rem);
  white-space: nowrap;
}
.itsm-chatroom__read-by-details {
  text-align: center;
  min-width: calc(120 / 16 * 1rem);
  font-size: calc(11 / 16 * 1rem);
}
.itsm-chatroom__skeleton {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/chat-room/chat-room.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;AADF;AAGE;EACE,iDAAA;AADJ;AAEI;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EACA,eAAA;AAAN;AAEI;EACE,kCAAA;AAAN;AAEI;EACE,sBAAA;EACA,kCAAA;EACA,0BAAA;EACA,+BAAA;EACA,mBAAA;AAAN;AACM;EACE,gBAAA;AACR;AAEI;EACE,0BAAA;EACA,+BAAA;EACA,mBAAA;AAAN;AAIE;EACE,sBAAA;AAFJ;AAKE;EACE,aAAA;EACA,8BAAA;AAHJ;AAME;EACE,gBAAA;EACA,eAAA;EACA,+BAAA;EACA,mBAAA;AAJJ;AAOE;EACE,kBAAA;EACA,gCAAA;EACA,+BAAA;AALJ;AAQE;EACE,WAAA;AANJ","sourcesContent":["@import '~mixins';\n\n.itsm-chatroom {\n  margin-top: toRem(30);\n\n  .ant-card-body {\n    padding: toRem(5) toRem(20);\n    .ant-list-item-meta {\n      display: flex;\n      flex: 1 1;\n      align-items: flex-start;\n      max-width: 100%;\n    }\n    .ant-list-item-meta-avatar {\n      margin-right: toRem(16);\n    }\n    .ant-list-item-meta-title {\n      word-break: break-word;\n      margin-bottom: toRem(4);\n      color: rgba(0, 0, 0, 0.85);\n      font-size: toRem(14);\n      line-height: 1.5715;\n      p {\n        margin-bottom: 0;\n      }\n    }\n    .ant-list-item-meta-description {\n      color: rgba(0, 0, 0, 0.45);\n      font-size: toRem(14);\n      line-height: 1.5715;\n    }\n  }\n\n  &__avatar {\n    vertical-align: middle;\n  }\n\n  &__title {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  &__read-by {\n    font-weight: 400;\n    cursor: pointer;\n    font-size: toRem(12);\n    white-space: nowrap;\n  }\n\n  &__read-by-details {\n    text-align: center;\n    min-width: toRem(120);\n    font-size: toRem(11);\n  }\n\n  &__skeleton {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
