// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-invite-error-modal .ant-form {
  width: 100%;
  margin-top: calc(16 / 16 * 1rem);
}

.issue-form .ant-form {
  width: 100%;
  margin-top: calc(16 / 16 * 1rem);
}
.issue-form .ant-form-item {
  margin-bottom: 0;
}
.issue-form .ant-form-item-label {
  width: 100%;
  text-align: left;
}
.issue-form .ant-form-item-explain {
  text-align: left;
}
.issue-form textarea {
  height: calc(115 / 16 * 1rem);
  resize: none;
}

.issue-modal .ant-form {
  width: 100%;
  margin-top: calc(16 / 16 * 1rem);
}
.issue-modal .ant-modal-content {
  padding: calc(16 / 16 * 1rem);
}`, "",{"version":3,"sources":["webpack://./src/sites/accept-org-invite/error-modal/accept-invite-error-modal.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,gCAAA;AAFJ;;AAOE;EACE,WAAA;EACA,gCAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AAQE;EACE,WAAA;EACA,gBAAA;AANJ;AASE;EACE,gBAAA;AAPJ;AAUE;EACE,6BAAA;EACA,YAAA;AARJ;;AAaE;EACE,WAAA;EACA,gCAAA;AAVJ;AAaE;EACE,6BAAA;AAXJ","sourcesContent":["@import '~mixins';\n\n.org-invite-error-modal {\n  .ant-form {\n    width: 100%;\n    margin-top: toRem(16);\n  }\n}\n\n.issue-form {\n  .ant-form {\n    width: 100%;\n    margin-top: toRem(16);\n  }\n\n  .ant-form-item {\n    margin-bottom: 0;\n  }\n\n  .ant-form-item-label {\n    width: 100%;\n    text-align: left;\n  }\n\n  .ant-form-item-explain {\n    text-align: left;\n  }\n\n  textarea {\n    height: toRem(115);\n    resize: none;\n  }\n}\n\n.issue-modal {\n  .ant-form {\n    width: 100%;\n    margin-top: toRem(16);\n  }\n\n  .ant-modal-content {\n    padding: toRem(16);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
