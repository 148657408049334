import request from 'superagent'

import {myAccessApi, getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getMyAccess = async () => {
  return await request
    .options(myAccessApi().get)
    .send({})
    .set(getHeadersWithGRPC())
}
