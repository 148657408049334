import request from 'superagent'

import {
  // getBackendTokenHeaders,
  getGrpcMetaSpace,
  // getHeadersWithGRPC,
} from '@/services/api'
import {getToken, getHeadersWithGRPC} from '@/modules/ITSM/api/generate-itsm-api-url'

import {RESOLVE} from '@/constants'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {
  assignedIncidentsFunction,
  incidentsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

import {
  TIncident,
  TIncidentReq,
  TIncidentRes,
} from '../components/incident-k-request/typedf'
import {TIncidentsStats} from '../typedef'

export const getIncidentsAssignedToMe = async (): Promise<ISuperAgentResSingle<{
  count: number
}>> => {
  return await request
    .options(assignedIncidentsFunction().post)
    .send({})
    .set(getHeadersWithGRPC())
}

export const getIncidents = async ({
  payloadBookmark,
  payloadSelector,
  payloadFields,
  payloadSort,
  resolve,
}: {
  payloadBookmark: string
  payloadSelector: string
  payloadFields: string
  payloadSort: string
  resolve: boolean
}): Promise<ISuperAgentResSingle<TIncident>> => {
  return await request
    .options(incidentsApi().get + resolve)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: payloadBookmark,
      selector: payloadSelector,
      fields: payloadFields,
      sort: payloadSort,
    })
}

export const fetchIncidents = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
  fields,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(incidentsApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
      fields,
    })

export const exportIncidents = async (
  query: Record<string, unknown>,
  fields: string[]
): Promise<ISuperAgentResSingle<TIncident>> => {
  return await request
    .options(incidentsApi().getReports + RESOLVE)
    .send({
      selector: query,
      fields,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const postIncident = async (
  data: TIncidentReq,
  id: string
): Promise<ISuperAgentResSingle<TIncidentRes>> => {
  return await request
    .post(incidentsApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getIncident = async (id: string) => {
  return await request
    .get(incidentsApi(id).getOne)
    .set(getHeadersWithGRPC())
    .query({resolve: true})
}

export const getLocation = async (id: string) => {
  return await request
    .get(incidentsApi(id).getLocation)
    .set(getHeadersWithGRPC())
}

export const postCloseIncident = async ({data}: {data: string}) => {
  return await request
    .post(incidentsApi().closeTicket)
    .set(getHeadersWithGRPC())
    .send({entity: data})
}

export const postCancelIncident = async (data: {
  entity: string
  cancellation_notes: string
}) => {
  return await request
    .post(incidentsApi().cancelIncident)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const patchIncident = async (
  data: Partial<TIncidentReq>,
  id?: string
): Promise<ISuperAgentResSingle<TIncidentRes>> => {
  return await request
    .patch(incidentsApi(id).getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchTicketsToCheck = async (data: {entity: string}) => {
  return await request
    .options(incidentsApi().getTicketCheck)
    .send(data)
    .set(getHeadersWithGRPC())
}

export const fetchCmdbSpaceInChannel = async () => {
  const grpcMeta = getGrpcMetaSpace()

  return await request.options(incidentsApi().getCMDSpaceForChannel).set({
    ...getToken(),
    'Grpc-Metadata-space': grpcMeta,
  })
}

export const fetchIncidentsStats = async (): Promise<ISuperAgentResSingle<
  TIncidentsStats
>> =>
  await request
    .options(incidentsApi().getIncidentsStats)
    .set(getHeadersWithGRPC())
