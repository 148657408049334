import request from 'superagent'

import {RESOLVE} from '@/constants'
import {
  incidentsApi,
  priorityApi,
  requestApi,
  getHeadersWithGRPC,
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getPriorityValues = async () => {
  return await request.get(priorityApi().get).set(getHeadersWithGRPC())
}

export const getIncident = async id => {
  return await request
    .get(incidentsApi(id).getOne + RESOLVE)
    .set(getHeadersWithGRPC())
}

export const getRequest = async id => {
  return await request
    .get(requestApi(id).getOne + RESOLVE)
    .set(getHeadersWithGRPC())
}
