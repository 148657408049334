import request from 'superagent'

import {channelApi} from '@/services/api'
import {createMetadataSpaceHeader} from '@/utils/create-metadata-space-header'
import {
  getToken,
  incidentsApi,
  itsmMySpacesApi,
  getHeadersWithGRPC,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {ISuperAgentResSingle} from '@/api/response-typedf'

export const getSpace = async (id: string) => {
  return await request
    .get(itsmMySpacesApi().getOne(id))
    .set(getHeadersWithGRPC())
}

export const getSpaces = async (headers: Record<string, string>) => {
  return await request
    .get(itsmMySpacesApi().getSpaces)
    .set(headers || getHeadersWithGRPC())
}

export const postSpace = async (payload: {
  channel_name: string
  cmdb_space_id: string
  saas_space_id: string
}) => {
  return await request
    .post(channelApi().post)
    .set(getToken())
    .send(payload)
}

export const upgradeSpace = async ({
  channel_name,
  space,
}: {
  channel_name: string
  space: string
}) => {
  return await request
    .patch(channelApi().patchChannel)
    .set({
      ...getToken(),
      ...createMetadataSpaceHeader(space),
    })
    .send({channel_name})
}

export const getRefCmdbSpaceId = async (): Promise<ISuperAgentResSingle<{
  cmdb_space_id: string
}>> => {
  return await request
    .options(incidentsApi().getCMDSpaceForChannel)
    .set(getHeadersWithGRPC())
}

export const getRefSaasSpaceId = async (): Promise<ISuperAgentResSingle<{
  saas_space_id: string
}>> => {
  return await request
    .options(incidentsApi().getSAASSpaceForChannel)
    .set(getHeadersWithGRPC())
}
