import {generateConfig} from '@/generateConfig'

import store from '../../../redux/store'

const mainURL = generateConfig().BASE_URL
const apiUrl = generateConfig().API_IDENTITY_URL

export const url = `${generateConfig().API_ITSM_URL}${
  generateConfig().API_ITSM_PATH_PREFIX
}${generateConfig().API_ITSM_V1}`
export const url_v2 = `${generateConfig().API_ITSM_URL}${
  generateConfig().API_ITSM_PATH_PREFIX
}${generateConfig().API_ITSM_V2}`

const orchestrator = generateConfig().ORCHESTRATOR_URL

export function getToken() {
  const {backEndToken} = store.getState().itsmUser
  return {
    Authorization: `Bearer ${backEndToken}`,
  }
}

export function getHeadersWithGRPC() {
  const {channelID} = store.getState().entries

  const headers = getToken()

  return {
    'Grpc-Metadata-space': channelID,
    ...headers,
  }
}

export const incidentsApi = (id?: string) => {
  const incidents = '/assets/incident'
  return {
    get: url + incidents,
    post: url + incidents + '/' + id,
    getOne: url + incidents + '/' + id,
    getReports: (format: string) => url + '/reports/incident?type=' + format,
    closeTicket: url + '/functions-invoke/closeTicket',
    getLocation: mainURL + '/blits-tracking/api/v1/location/' + id,
    cancelIncident: url + '/functions-invoke/cancelTicket',
    getTicketCheck: url + '/functions-query/ticketCheck',
    getCMDSpaceForChannel: `${url}/functions-query/getCMDBSpaceID`,
    getSAASSpaceForChannel: `${url}/functions-query/getSAASSpaceID`,

    getIncidentsStats: `${url}/functions-query/incidentStatsAggregate`,
  }
}

export const k_requestsApi = (id?: string) => {
  const k_requests = '/assets/k_request'
  return {
    get: url + k_requests,
    post: url + k_requests + '/' + id,
    getOne: url + k_requests + '/' + id,
    getReports: (format: string) => url + '/reports/k_request?type=' + format,
    cancelIncident: url + '/functions-invoke/cancelTicket',
    getRequestsStats: `${url}/functions-query/requestStatsAggregate`,
  }
}

export const incidentTaskApi = (id?: string) => {
  const task = '/assets/incident_task'
  return {
    get: url + task,
    post: url + task + '/' + id,
    patch: url + task + '/' + id,
    getOne: url + task + '/' + id,
  }
}

export const timeLogApi = (id?: string) => {
  return {
    getTimelogs: url + '/assets/timelog',
    getOne: url + '/assets/timelog/' + id,
    postCheckout: url + '/functions-invoke/closeTimelog',
    changeVisitSummary: url + '/functions-invoke/updateVisitSummary',
    updateMyTimespan: url + '/functions-invoke/updateMyTimelogTimespan',
    updateTimespan: url + '/functions-invoke/updateTimelogTimespan',
    cancelTimelogByEngineer: url + '/functions-invoke/cancelTimelogByEngineer',
    cancelTimelogByServiceDesk:
      url + '/functions-invoke/cancelTimelogByServiceDesk',
    editTravelTime: url + '/functions-invoke/updateTimelogTravel',
    updateTimelog: url + '/assets/timelog/' + id,
  }
}

export const identitiesApi = (id?: string) => {
  return {
    get: `${url}/identities`,
    post: `${url}/identities/me`,
    getQuery: `${url}/identities`,
    getMe: `${url}/identities/me`,

    getOne: `${url}/identities/me/${id}`,
    patch: `${url}/identities/me/${id}`,
  }
}

export const requestApi = (id: string) => {
  const requests = '/assets/request'
  return {
    get: url + requests,
    getOne: url + requests + '/' + id,
    patch: url + requests + '/' + id,
    post: url + requests + '/' + id,
    getReports: (format: string) => url + '/reports/request?type=' + format,
  }
}

export const requestItemApi = (id: string) => {
  const item = '/assets/request_item'
  return {
    get: url + item,
    getOne: url + item + '/' + id,
    patch: url + item + '/' + id,
    post: url + item + '/' + id,
  }
}

export const requestTaskApi = (id: string) => {
  const task = '/assets/request_task'
  return {
    get: url + task,
    getOne: url + task + '/' + id,
    patch: url + task + '/' + id,
    post: url + task + '/' + id,
  }
}

export const rejectedReasonsApi = (id?: string) => {
  const rejectedReason = '/assets/rejected_reason'
  return {
    get: url + rejectedReason,
    post: url + rejectedReason + '/',
    patch: url + rejectedReason + '/' + id,
  }
}

export const timeSessionApi = (id: string) => {
  return {
    post: url + '/assets/time_session/',
    get: url + '/assets/time_session',
    getOne: url + '/assets/time_session/' + id,
    add: url + '/functions-invoke/addEntityToTimeSession',
    addTimeSessionTimespan: url + '/functions-invoke/addTimeSessionTimespan',
    updateTimeSessionTimespan:
      url + '/functions-invoke/updateTimeSessionTimespan',
    deleteTimeSessionTimespan:
      url + '/functions-invoke/deleteTimeSessionTimespan',
  }
}

export const acceptOrRejectTicketApi = () => {
  const acceptOrReject = '/functions-invoke/acceptOrRejectTicket'
  return {
    post: url + acceptOrReject,
  }
}

export const approveOrRejectRequestItemApi = () => {
  const approveOrReject = '/functions-invoke/approveOrRejectRequestItem'
  return {
    post: url + approveOrReject,
  }
}

export const cancelRequestApi = () => {
  return {
    post: url + '/functions-invoke/cancelRequest',
  }
}

export const cancelRequestItemApi = () => {
  return {
    post: url + '/functions-invoke/cancelRequestItem',
  }
}

export const closeRequestItemApi = () => {
  return {
    post: url + '/functions-invoke/closeRequestItem',
  }
}

export const waitingForApprovalApi = () => {
  return {
    post: url + '/functions-invoke/setRequestItemToWaitingForApproval',
  }
}

export const userApi = (id?: string) => {
  const user = '/assets/user'
  return {
    get: url + user,
    getOne: url + user + '/' + id,
    post: url + user + '/',
    getQuery: url + '/user-query',
    getByNameSurname: url + '/user-by-name-surname',
    createNew: url + user + '/',
    getSuppliers: (exportQuery = '') =>
      url + '/functions-query/getSupplierUsers' + exportQuery,
    getForAccount: apiUrl + '/accounts/' + id + '/subs',
  }
}

export const assignmentRulesApi = (id?: string) => {
  const assignmentRule = '/assets/assignment_rule'
  return {
    post: url + assignmentRule + '/' + id,
    get: url + assignmentRule,
  }
}

export const expensesApi = () => {
  const expenses = '/assets/expense'
  return {
    get: url + expenses,
    post: (id?: string) => url + expenses + '/' + id,
    getAll: url + expenses + '?resolve=false',
    singleton: url + '/singletons/settings',
    approve: url + '/functions-invoke/approveExpense',
    delete: url + '/functions-invoke/removeExpense',
  }
}

export const notificationsApi = (id?: string) => {
  const notification = '/assets/notification'
  return {
    get: url + notification,
    post: url + notification + '/' + id,
    patch: url + notification + '/' + id,
  }
}

export const announcementApi = (id?: string) => {
  const announcement = '/assets/announcement'

  return {
    post: url + announcement + '/',
    get: url + announcement,
    getOne: `${url}${announcement}/${id}`,
    patch: url + announcement + '/' + id,
  }
}

export const attachmentsV2 = () => {
  return {
    post: (uuid: string, entity: string, name: string) =>
      `${url_v2}/attachments?entity=${entity}:${uuid}&file_name=${name}`,
    get: (uuid: string) => `${url_v2}/attachments/${uuid}`,
    getAll: (uuid: string, entity: string, offset: string) =>
      `${url_v2}/attachments?entity=${entity}:${uuid}&offset=${offset}`,
    getOne: (uuid: string) => `${url_v2}/attachments/${uuid}`,
  }
}

export const BRattachmentsApi = () => {
  return {
    post: (id: string) => url + '/invoices/attach/' + id,
    getAll: (ref: string) => url + '/invoices/download/' + ref,
  }
}

export const invitationApi = {
  post: url + '/invites/',
  postInvitation: `${url}/invites/org`,
  acceptOrgInvite: `${orchestrator}/v1/itsm/accept-invite`,
  get: url + '/invites/',
  patch: (id: string) => url + '/invites/' + id,
}

export const assignedIncidentsFunction = () => {
  return {
    post: url + '/functions-query/incidentsOpenAssignedToMe',
  }
}

export const orgRolesApi = () => {
  return {
    get: url + '/singletons/org_roles',
  }
}

export const roleApi = (id?: string) => {
  const roles = '/roles'
  return {
    get: url + roles,
    getOne: url + roles + '/' + id,
    patch: url + roles + '/' + id,
    options: url + roles,

    post: url + roles + '/' + id,

    getOverrides: url + '/kompiguard-overrides',
    putOverrides: url + '/kompiguard-overrides',
    getMyRoles: url + '/functions-query/rolesGrantableByMe',
    getUserRoles: url + '/functions-query/getUserRoles',
    patchUserRoles: url + '/functions-invoke/setUserRoles',
  }
}

export const versionApi = () => {
  return {
    getVersion: `${url}/functions-query/getVersion`,
  }
}

export const locationsApi = (id?: string) => {
  const locations = '/assets/location'
  return {
    get: url + locations,
    getQuery: url + locations,
    getOne: url + locations + '/' + id,
    post: url + locations + '/' + id,
  }
}

export const categoriesApi = (id?: string) => {
  const category = '/assets/category'
  return {
    get: url + category,
    getOne: url + category + '/' + id,
    post: url + category + '/',
    patch: url + category + '/' + id,
  }
}

export const myOrganizationApi = {
  get: url + '/my-organization',
}

// GENERAL
export const itsmMySpacesApi = () => ({
  get: `${url}/my-spaces`,
  getOne: (id: string) => `${url}/sub-space-by-id/${id}`,
  getSpaces: `${url}/sub-spaces-by-app?appName=itsm`,
})

export const itsmOrganizationApi = () => ({
  get: url + '/orgs-by-sub-app?appName=itsm',
  getForAccount: (accountId: string) =>
    apiUrl + '/accounts/' + accountId + '/orgs',
  create: (accountId?: string) => apiUrl + '/accounts/' + accountId + '/orgs',
})

export const billingReportsApi = (id?: string) => {
  const billing_report = '/assets/billing_report'
  return {
    post: url + '/functions-invoke/billingReportGenerate',
    setApproval: url + '/functions-invoke/billingReportSetApproval',
    get: url + billing_report,
    getOne: url + billing_report + '/' + id,
    getCurrent: url + '/functions-query/billingReportPreview',
    getReports: (type: string) => url + '/reports/billing_report?type=' + type,
    postInvalidAnomaly: url + '/functions-invoke/billingReportAnomalyInvalid',
    approve: url + '/assets/billing_report_approval/',
    approved: url + '/assets/billing_report_approval',
    singleton: url + '/singletons/settings',
    getList: `${url}/functions-query/billingReportsList`,
  }
}

export const creditSystem = () => {
  return {
    options: url + '/functions-query/getCreditMode',
    post: url + '/functions-invoke/addCredit',
    get: url + '/singletons/settings', //checks if user has acces to credit
  }
}

export const citiesApi = () => {
  const url = 'https://countriesnow.space/api/v0.1/countries'
  return {
    cities: `${url}/cities`,
    countries: `${url}/positions`,
  }
}

export const assignmentGroupsApi = (id?: string) => {
  const assignmentGroup = '/assets/assignment_group'
  return {
    post: url + assignmentGroup + '/' + id,
    get: url + assignmentGroup,
  }
}

export const productApi = (id?: string) => {
  const supplierProduct = '/assets/supplier_product'
  const customerProduct = '/assets/customer_product'

  return {
    customer: url + customerProduct,
    getOne: url + customerProduct + '/' + id,
    supplier: url + supplierProduct,
    supplierGetOne: url + supplierProduct + '/' + id,
    patchSupplier: url + supplierProduct + '/' + id,
    patchCustomerProduct: url + customerProduct + '/' + id,
    postCustomerProduct: url + customerProduct + '/' + id,
    postSupplierProduct: url + supplierProduct + '/' + id,
  }
}

export const userGroupsApi = (id?: string) => {
  const user_group = '/assets/user_group'
  return {
    get: url + user_group,
    post: url + user_group + '/' + id,
  }
}

export const creditSystemHistory = () => {
  return {
    options: url + '/assets/credit_item', //returns all credit items
  }
}

export const eventsApi = () => {
  const event = '/blits-drafts/api/v1'
  return {
    get: mainURL + event,
    getOne: mainURL + event + '/incident',
    put: mainURL + event + '/incident',
    approve: mainURL + event + '/incident-approve',
  }
}

export const accountApi = (accountId?: string) => ({
  create: apiUrl + '/accounts',
  getAccount: apiUrl + '/accounts/' + accountId,
  getOne: apiUrl + '/accounts/' + accountId + '/orgs',
  get: apiUrl + '/accounts',
  createOrg: apiUrl + '/accounts/' + accountId + '/orgs',
  getUserCert: mainURL + '/api/v1/usercerts',
  getGrantLevel: `${apiUrl}/accounts/${accountId}/my-grant-level`,
  grant: apiUrl + '/accounts/' + accountId + '/grant',
  inviteUser: apiUrl + '/accounts/' + accountId + '/invite',
  getAll: apiUrl + '/accounts?mode=list-all',
  checkAccess: apiUrl + '/accounts/' + accountId + '/my-allowed-components',
})

export const billingItemsApi = () => {
  return {
    get: url + '/functions-query/getMyBillingItems2',
    getReports: (format: string) => url + '/docs/billing-items?type=' + format,
  }
}

export const documents = (space?: string) => {
  return {
    list: url + '/documents/' + space + '/list.json',
    getOne: (file: string) => url + '/documents/' + space + '/' + file,
    docsRead: url + '/functions-invoke/confirmDocsRead',
  }
}
export const customReports = () => {
  return {
    get: (query: string) => `${url}/reports/${query}`,
  }
}

export const documentStorageApi = () => {
  return {
    post: (name: string, queryParams?: string) =>
      `${url}/docs/?name=${name}${queryParams}`,
    get: url + '/docs/channel',
    download: (id: string) => url + '/docs/' + id + '?data=1',
    delete: (id: string) => url + '/docs/unlink/' + id,
  }
}

export const priorityApi = () => {
  return {
    get: url + '/singletons/priority_matrix',
  }
}

export const myAccessApi = () => {
  return {
    get: `${url}/functions-query/myAccess`,
  }
}

export const myCmdbUserIdApi = () => {
  return {
    get: `${url}/functions-query/getUsersIdentity`,
  }
}

export const customReportApi = () => {
  return {
    get: url + '/reports/ticket?resolve=true&type=xlsx',
  }
}

export const workNotesApi = () => {
  return {
    get: (entity?: string, limit?: number, bookmark?: string) =>
      url_v2 +
      `/worknotes?${entity ? `entity=${entity}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${bookmark ? `&bookmark=${bookmark}` : ''}`,
    post: url_v2 + '/worknotes',
    postReadBy: (id: string) => url_v2 + `/worknotes/${id}/read_by`,
    getOne: (id: string) => url_v2 + `/worknotes/${id}`,
  }
}

export const commentsApi = () => {
  return {
    get: (entity?: string, limit?: number, bookmark?: string) =>
      url_v2 +
      `/comments?entity=${entity}&limit=${limit}` +
      (bookmark ? '&bookmark=' + bookmark : ''),
    post: url_v2 + '/comments',
    postReadBy: (id: string) => url_v2 + `/comments/${id}/read_by`,
    getOne: (id: string) => url_v2 + `/comments/${id}`,
  }
}
export const incidentGraphReport = () => ({
  options: () => `${url}/functions-query/incidentGraphReport`,
})

export const requestGraphReport = () => ({
  options: () => `${url}/functions-query/requestGraphReport`,
})
