// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-select label,
.location-select .ant-form-item-label {
  width: 100%;
}
.location-select__label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/locationSelect/location-select.scss"],"names":[],"mappings":"AACE;;EAEE,WAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;AADJ","sourcesContent":[".location-select {\n  label,\n  .ant-form-item-label {\n    width: 100%;\n  }\n\n  &__label {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
