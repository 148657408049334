import {call, put, select} from 'redux-saga/effects'

import {fetchSubscriptions} from '@/api/billing-requests'
import {extractFeatureFlagsSubscriptions} from '@/utils/extract-feature-flags-subscriptions'
import {
  fetchBillingSubscriptionsFailed,
  fetchBillingSubscriptionsSucceeded,
} from '@/redux/billing/billing-action-creators'
import {getBEndToken} from '@/api/token-request'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {selectModuleProfile} from '@/redux/profile/profileSelector'

export function* getBillingSubscriptionsSaga() {
  const {orgId: id} = yield select(selectModuleProfile)

  try {
    const {
      body: {access_token},
    } = yield call(getBEndToken, {
      id,
      scope: 'acc',
    })

    const {
      body: {result: fetchedSubscrs},
    } = yield call(fetchSubscriptions, access_token)

    const featureFlags = extractFeatureFlagsSubscriptions(fetchedSubscrs)
    yield put(
      fetchBillingSubscriptionsSucceeded({
        subscriptions: fetchedSubscrs,
        featureFlags,
      })
    )
  } catch (err) {
    yield put(
      setToastMessage({
        message: err,
      })
    )
    yield put(fetchBillingSubscriptionsFailed())
  }
}
