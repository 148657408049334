import {ItsmAssets} from '../typedef'

import {fetchAnnouncements} from './announcementsRequests'
import {fetchAssignmentGroups} from './assignmentGroupRequests'
import {fetchAssignmentRules} from './assignmentRulesRequests'
import {fetchCategories} from './categoriesRequests'
import {fetchExpenses, fetchTimelogExpenses} from './expensesRequest'
import {fetchCreditHistory} from './creditRequests'
import {getIdentities} from './identityRequests'
import {fetchIncidents} from './incidentsRequests'
import {fetchIncidentTasks} from './incidentTasksRequests'
import {fetchRequests} from './kRequestsRequests'
import {fetchNotifications} from './notificationRequests'
import {fetchCustomerProducts, fetchSupplierProducts} from './productsRequests'
import {fetchReasons} from './reasonsRequests'
import {fetchRoles} from './rolesRequests'
import {fetchTimeLogs} from './timelogsRequests'
import {fetchUserGroups} from './userGroupRequests'
import {fetchSuppliers, fetchUsers} from './userRequests'
import {fetchBillingItems} from './billingReportsRequests'
import {getInvitations} from './invitationsRequests'
import {fetchLocations} from './locationRequests'
import {fetchEvents} from './eventsRequests'
import {fetchDocs} from './documents-api'

export const apiService = {
  [ItsmAssets.AssignmentGroups]: fetchAssignmentGroups,
  [ItsmAssets.Roles]: fetchRoles,
  [ItsmAssets.Identities]: getIdentities,
  [ItsmAssets.Users]: fetchUsers,
  [ItsmAssets.IncidentsUP]: fetchIncidents,
  [ItsmAssets.RequestsUP]: fetchRequests,
  [ItsmAssets.Suppliers]: fetchSuppliers,
  [ItsmAssets.RejectReasons]: fetchReasons,
  [ItsmAssets.UserGroups]: fetchUserGroups,
  [ItsmAssets.AssignmentRules]: fetchAssignmentRules,
  [ItsmAssets.Announcements]: fetchAnnouncements,
  [ItsmAssets.SupplierProducts]: fetchSupplierProducts,
  [ItsmAssets.CustomerProducts]: fetchCustomerProducts,
  [ItsmAssets.Notifications]: fetchNotifications,
  [ItsmAssets.Categories]: fetchCategories,
  [ItsmAssets.Incidents]: fetchIncidents,
  [ItsmAssets.Requests]: fetchRequests,
  [ItsmAssets.TimeLogs]: fetchTimeLogs,
  [ItsmAssets.TimelogExpenses]: fetchTimeLogs,
  [ItsmAssets.SVR]: fetchTimeLogs,
  [ItsmAssets.TimeLogsResolve]: fetchTimeLogs,
  [ItsmAssets.IncidentExpenses]: fetchTimelogExpenses,
  [ItsmAssets.Expenses]: fetchExpenses,
  // [ItsmAssets.IncidentExpenses]: fetchExpenses,
  [ItsmAssets.IncidentTasks]: fetchIncidentTasks,
  [ItsmAssets.Credits]: fetchCreditHistory,
  [ItsmAssets.BillingItems]: fetchBillingItems,
  [ItsmAssets.Invitations]: getInvitations,
  [ItsmAssets.Locations]: fetchLocations,
  [ItsmAssets.Events]: fetchEvents,
  [ItsmAssets.Documents]: fetchDocs,
}
