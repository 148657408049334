import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom/client';

import queryString from 'query-string'
import {I18nextProvider} from 'react-i18next'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {createRoot} from 'react-dom/client'
// import {AuthProvider} from 'react-oidc-context'
import { Auth0Provider } from '@auth0/auth0-react';

import {generateConfig} from '@/generateConfig'

import App from './App'
import store, {persistor} from './redux/store'
import {unregister} from './service-worker-registration'
import i18n from './services/i18n'
import {sentryInit} from './services/sentry'
//import {customerlyInit} from './services/customerly/customerly'

const postRender = async () => {
  sentryInit()
//  await customerlyInit()
}

// if (process.env.NODE_ENV === 'development') {
//   const {worker} = require('./msw/browser')
//   worker.start()
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const oidcConfig = {
//   authority: generateConfig().OIDC_AUTHORITY,
//   client_id: generateConfig().OIDC_CLIENT_ID,
//   redirect_uri: generateConfig().OIDC_REDIRECT_URI,
//   post_logout_redirect_uri: generateConfig().OIDC_POST_LOGOUT_REDIRECT_URI,
//   scope: generateConfig().OIDC_SCOPE,
//   automaticSilentRenew: true,
// }

// const onSignIn = user => {
//   const url = queryString.stringifyUrl({
//     url: window.location.href,
//     query: user.state,
//   })
//   window.history.replaceState({}, '', url)
// }

const providerConfig = {
  domain: generateConfig().AUTH0_DOMAIN,
  clientId: generateConfig().AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...{ audience: generateConfig().AUTH0_AUDIENCE },
  },
};

root.render(
  // <AuthProvider {...oidcConfig} onSigninCallback={onSignIn}>
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={generateConfig().BASEPATH}>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  {/* </AuthProvider>  */}
  </Auth0Provider>
)

postRender()
unregister()
