import request from 'superagent'

import {
  accountApi,
  getToken,
  userApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {getBackendTokenHeaders} from '@/services/api'

export const getAccount = async id => {
  return await request.get(accountApi(id).getAccount).set(getBackendTokenHeaders())
}

export const getOrgAccount = async id => {
  console.log('there?')
  return await request.get(accountApi(id).getOne).set(getBackendTokenHeaders())
}

export const fetchAccountsSaga = (myAccounts, auth) => {
  const endpoint = myAccounts ? accountApi().get : accountApi().getAll
  return request.get(endpoint).set(auth)
}

export const getSubs = async id => {
  return await request.get(userApi(id).getForAccount).set(getBackendTokenHeaders())
}

export const getGrantLevel = async id => {
  return await request
    .get(accountApi(id).getGrantLevel)
    .set(getBackendTokenHeaders())
}

export const getUserCerts = async () => {
  return await request.get(accountApi().getUserCert).set(getBackendTokenHeaders())
}

export const postAccount = async data => {
  return await request
    .post(accountApi().create)
    .set(getToken())
    .send(data)
}

export const patchGrant = async (accountId, subId, grantLevel) => {
  return await request
    .post(accountApi(accountId).grant)
    .set(getToken())
    .send({
      sub: subId,
      grantLevel,
    })
}

export const deleteGrant = async (id, query) => {
  return await request
    .delete(accountApi(id).grant)
    .send(query)
    .set(getBackendTokenHeaders())
}
