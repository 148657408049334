import globalRoutes from '@/global-routes'
import {generateConfig} from '@/generateConfig'

import {TUserDefaultsInvite, TUserInviteReq} from './typedf'
import store from '@/redux/store'

export const createUserDefaults = (values: TUserDefaultsInvite) => {
  return {
    notification: values.notification,
    unavailable: values.unavailable,
    contract_type: values.contract_type,
    ...(values.email ? {email: values.email} : {}),
    type: values.type,
    ...(values.app_version ? {app_version: values.app_version} : {}),
    ...(values.assignment_groups && values.assignment_groups.length
      ? {assignment_groups: values.assignment_groups}
      : {}),
    ...(values.company ? {company: values.company} : {}),
    ...(values.email_signature
      ? {email_signature: values.email_signature}
      : {}),
    ...(values.name ? {name: values.name} : {}),
    ...(values.phone ? {phone: values.phone} : {}),
    ...(values.surname ? {surname: values.surname} : {}),
    ...(values.timezone ? {timezone: values.timezone} : {}),
    ...(values.user_groups && values.user_groups.length
      ? {user_groups: values.user_groups}
      : {}),
    ...(values.pricing_policy ? {pricing_policy: values.pricing_policy} : {}),
    ...(values.manager ? {manager: values.manager} : {}),
  }
}

export const createInviteData = ({
  values: {email, channel, roles, noCmdbInvite, noSaasInvite},
  user_defaults,
}: {
  values: TUserInviteReq
  user_defaults: TUserDefaultsInvite
}) => {
  return {
    email,
    redirectUrl: `${generateConfig().BASE_URL}${generateConfig().BASEPATH}/`,
    signingPath: globalRoutes.general.acceptInvitationITSM.path(),
    token_back: store.getState().itsmUser.accessToken,
    spaces: [
      {
        uuid: channel,
        roles,
        user_defaults,
        ...(noSaasInvite && {noSaasInvite}),
        ...(noCmdbInvite && {noCmdbInvite}),
      },
    ],
  }
}
