import React, {useContext, useEffect, useLayoutEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Col, Row} from 'antd'
import {DollarOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router'

import {OverlaySpinner} from '@/components/overlay-spinner'
import {Modules} from '@/typedef'
import SvgIcon from '@/components/svg-icon/svg-icon'
import {Paper} from '@/components/paper/paper'
import TopHeader from '@/core/app-shell/top-header/top-header'
import {AbilityContext} from '@/services/abilities/ability-context'

import './home.scss'

import subscriptionsRoutes from '@/modules/Subscriptions/routes/subscriptions-routes'

import {translate} from '@/services/i18n'
import {setModuleName} from '@/redux/user/userActions'
import {selectorIsLoadedSubscriptions} from '@/redux/billing/billing-selector'

import {MODULES} from '@/sites/home/constants/modules'

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const ability = useContext(AbilityContext)

  const isLoadedSubscriptions = useSelector(selectorIsLoadedSubscriptions)

  useLayoutEffect(() => {
    localStorage.removeItem('persist:root')
  }, [])

  useEffect(() => {
    dispatch(setModuleName(''))
  }, [dispatch])

  const handleModuleRedirect = (path: string) => {
    history.push(path)
  }

  const handleClickMenu = (redirectTo: string) => history.push(redirectTo)

  const handleSubscriptionRedirect = () => {
    history.push(subscriptionsRoutes.plansPackages.path())
  }

  // if (isLoadedSubscriptions) {
  //   return <OverlaySpinner isLogoutButtonVisible idName="home" />
  // }

  return (
    <div className="home">
      <div className="home__header">
        <SvgIcon name={'kompitech-theme'} width={28} />
        <TopHeader
          isShowBalance={false}
          handleClickUserMenu={handleClickMenu}
        />
      </div>

      <div className="home__wrapper">
        <div className="home__title">
          <h3 className="mb-20">{translate('welcome_text')}</h3>
          <h5>{translate('select_category_get_started')}</h5>
        </div>
        <Row gutter={[20, 20]} className="home__modules">
          {Object.values(MODULES).map(module => (
            <Col key={module.name} xs={24} md={10} lg={8} xl={6}>
              <Paper
                className="home__module"
                onClick={() => handleModuleRedirect(module.path)}
                e2eTest={`${module.name}-module-btn`}
              >
                <SvgIcon name={module.icon} className="home__svg" />
                <div className="home__module-title">{module.title}</div>
                <div className="home__module-description">
                  {module.description}
                </div>
              </Paper>
            </Col>
          ))}
          <Col xs={24} md={20} lg={24} xl={18} className="mt-30">
            {ability.can('view', Modules.Subscriptions) && (
              <Paper
                className="home__subscription"
                onClick={handleSubscriptionRedirect}
              >
                <div>
                  <DollarOutlined className="home__svg mr-10" />
                </div>
                <div className="home__subscription-tag">
                  {translate('view_subscriptions_and_services')}
                </div>
              </Paper>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Home
