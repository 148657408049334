// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-comment-form__file-attaching {
  margin-bottom: calc(10 / 16 * 1rem);
  display: flex;
  gap: calc(10 / 16 * 1rem);
  flex-flow: wrap;
}
.notes-comment-form__file-attaching .button.ant-btn {
  padding-right: calc(8 / 16 * 1rem);
  padding-left: calc(8 / 16 * 1rem);
  font-size: calc(14 / 16 * 1rem);
}
@media (min-width: 576px) {
  .notes-comment-form__file-attaching .button.ant-btn {
    padding-right: calc(16 / 16 * 1rem);
    padding-left: calc(16 / 16 * 1rem);
    font-size: calc(16 / 16 * 1rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/comments-list/notes-comments-form/notes-comments-form.scss","webpack://./src/styles/_media-queries.scss"],"names":[],"mappings":"AAGA;EACE,mCAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;AAFF;AAIE;EACE,kCAAA;EACA,iCAAA;EACA,+BAAA;AAFJ;ACgBI;EDjBF;IAMI,mCAAA;IACA,kCAAA;IACA,+BAAA;EADJ;AACF","sourcesContent":["@import '~mixins';\n@import '~mediaQueries';\n\n.notes-comment-form__file-attaching {\n  margin-bottom: toRem(10);\n  display: flex;\n  gap: toRem(10);\n  flex-flow: wrap;\n\n  .button.ant-btn {\n    padding-right: toRem(8);\n    padding-left: toRem(8);\n    font-size: toRem(14);\n\n    @include respond-above(xs) {\n      padding-right: toRem(16);\n      padding-left: toRem(16);\n      font-size: toRem(16);\n    }\n  }\n}\n","//\n//  MEDIA QUERIES\n//––––––––––––––––––––––––––––––––––––––––––––––––––\n\n// A map of breakpoints.\n$breakpoints: (\n  xs: 576px,\n  sm: 768px,\n  md: 992px,\n  lg: 1200px,\n  xl: 1600px,\n  xxl: 2100px,\n);\n\n//\n//  RESPOND ABOVE\n//––––––––––––––––––––––––––––––––––––––––––––––––––\n\n// @include respond-above(sm) {}\n@mixin respond-above($breakpoint) {\n  // If the breakpoint exists in the map.\n  @if map-has-key($breakpoints, $breakpoint) {\n    // Get the breakpoint value.\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    // Write the media query.\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n\n    // If the breakpoint doesn't exist in the map.\n  } @else {\n    // Log a warning.\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
