// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-form {
  height: 100%;
}
.drawer-form > div:first-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .drawer-form .col-flex--two .form-item-wrapper {
    width: calc(100% - 20px);
  }
  .drawer-form .col-flex--two .form-item-wrapper:nth-child(1) {
    margin-right: calc(20 / 16 * 1rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/ITSM/components/drawer-form/drawer-form/drawer-form.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAAJ;;AAIA;EAIQ;IACE,wBAAA;EAJR;EAMM;IACE,kCAAA;EAJR;AACF","sourcesContent":["@import '~mixins';\n\n.drawer-form {\n  height: 100%;\n  & > div:first-child {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n}\n\n@media screen and (min-width: 992px) {\n  .drawer-form {\n    .col-flex {\n      &--two {\n        .form-item-wrapper {\n          width: calc(100% - 40px / 2);\n        }\n        .form-item-wrapper:nth-child(1) {\n          margin-right: toRem(20);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
